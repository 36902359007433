import {
  CyclabilityZone,
  CyclabilityZoneService,
  OriginDestinationService,
  TH3Flow,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import centroid from '@turf/centroid';
import { cellsToMultiPolygon, polygonToCells } from 'h3-js';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import TabIntroduction from '../../../../components/tab-introduction';
import { TResolution } from '../../../../hooks/map/h3';
import useOriginDestinationFlows from '../../../../hooks/map/origin-destination-flows';
import { TOutletContext } from '../../../../layouts/page/container';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';
import { IBicycleObservatoryPageContext } from '../../context';
import { TH3CellFeatureProps } from '../../models/origin-destination';

import OriginDestinationChart from './chart';

function OriginDestinationForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    defaultPeriods,
    period,
    originDestination: {
      canvasRef,
      h3Resolution,
      zones,
      flows,
      setH3Resolution,
      setZones,
      setExternalH3Features,
      setFlows,
      selectH3Indices,
    },
    setLoading,
  } = context;
  const [initialized, setInitialized] = useState(false);
  const [h3ResolutionType, setH3ResolutionType] = useState<'cyclabilityZones' | 'h3'>(
    h3Resolution === 'cyclabilityZones' ? 'cyclabilityZones' : 'h3',
  );
  const {
    map: { current: currentMap, zoom },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    // initialized: layersInitialized,
    init: initLayers,
    clear: clearLayers,
  } = useOriginDestinationFlows({ context, map: currentMap, zoom });
  const { cancellablePromise: cancellableZonesPromise, cancelPromises: cancelZonesPromise } =
    useCancellablePromise();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    setInitialized(true);
    setLoading(true);

    return () => {
      cancelPromises();
      setFlows(undefined);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (
      initialized &&
      currentPartner?.administrativeLevel &&
      ['region', 'department', 'epci'].includes(currentPartner.administrativeLevel)
    )
      getZones();

    return () => {
      cancelZonesPromise();
    };
  }, [initialized]);

  useEffect(() => {
    if (initialized) {
      const newH3ResolutionType = h3Resolution === 'cyclabilityZones' ? 'cyclabilityZones' : 'h3';
      if (newH3ResolutionType !== h3ResolutionType) {
        cancelPromises();
        setFlows(undefined);
        setExternalH3Features(undefined);
        setH3ResolutionType(newH3ResolutionType);
      }
    }
  }, [initialized, h3Resolution]);

  useEffect(() => {
    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (currentMap && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [currentMap, canvasRef.current]);

  useEffect(() => {
    if (zones) getData(zones);

    return () => {
      cancelPromises();
      setFlows(undefined);
      setExternalH3Features(undefined);
    };
  }, [zones, period.values, h3ResolutionType]);

  useEffect(() => {
    return () => {
      selectH3Indices([]);
    };
  }, [period.values, h3Resolution]);

  useEffect(() => {
    setLoading(!flows);
  }, [flows]);

  async function getZones() {
    if (!currentPartner) return;

    try {
      const { count, zones } = await cancellableZonesPromise(
        CyclabilityZoneService.getZones({
          administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
          partnerCode: currentPartner.code,
          page: 1,
          rowsPerPage: 100,
          query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
        }),
      );

      if (count > 100) {
        const nbPages = Math.ceil(count / 100);
        const otherPagesZones = (
          await cancellableZonesPromise(
            Promise.all(
              new Array(nbPages - 1).fill(null).map((_, index) =>
                CyclabilityZoneService.getZones({
                  administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
                  partnerCode: currentPartner.code,
                  page: index + 2,
                  rowsPerPage: 100,
                  query:
                    '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
                }),
              ),
            ),
          )
        ).flatMap(({ zones }) => zones);

        zones.push(...otherPagesZones);
      }

      setZones(zones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  async function getData(zones: CyclabilityZone[]) {
    try {
      const zoneIds = zones.map(({ id }) => id);

      let flows: TH3Flow[];

      if (h3ResolutionType === 'cyclabilityZones') {
        const _flows = await cancellablePromise(
          OriginDestinationService.getFlows({
            period: period.values.current.toIPeriod(),
            departureCyclabilityZoneIds: zoneIds,
            arrivalCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput({ timePeriod: 'all_day', dayPeriod: 'all' }),
          }),
        );

        const _externalZonesIds: number[] = [];

        flows = _flows.map(({ origin, destination, count }) => {
          const isInternalOrigin = zoneIds.includes(origin);
          const isInternalDestination = zoneIds.includes(destination);

          if (!isInternalOrigin) _externalZonesIds.push(origin);
          if (!isInternalDestination) _externalZonesIds.push(destination);

          return {
            origin: `cyclability-zone-${origin}`,
            destination: `cyclability-zone-${destination}`,
            count,
            isInternalOrigin,
            isInternalDestination,
          };
        });

        const externalZonesIds = [...new Set(_externalZonesIds)];

        const { zones: externalZones } = await CyclabilityZoneService.getZones({
          ids: externalZonesIds,
          administrativeLevel: 'CITY',
          page: 1,
          rowsPerPage: Math.min(externalZonesIds.length, 100),
          query: '{ id, code, name, administrative_level, geo_polygon_simplified }',
        });

        const features: Array<GeoJSON.Feature<GeoJSON.Polygon, TH3CellFeatureProps>> = [];
        for (const { id, geometry, name } of externalZones) {
          if (geometry) {
            const h3Cells = polygonToCells(geometry.coordinates[0], 9);

            const polygon: GeoJSON.Polygon = {
              type: 'Polygon',
              coordinates: cellsToMultiPolygon(h3Cells, false)[0],
            };

            const [lng, lat] = centroid(polygon).geometry.coordinates;

            features.push({
              type: 'Feature',
              geometry: polygon,
              properties: {
                center: { lat, lng },
                resolution: 'cyclabilityZones',
                h3Index: `cyclability-zone-${id}`,
                zoneId: `${id}`,
                name,
              },
            });
          }
        }

        if (features.length > 0) setExternalH3Features(features);
      } else {
        flows = await cancellablePromise(
          OriginDestinationService.getH3Flows({
            period: period.values.current.toIPeriod(),
            departureCyclabilityZoneIds: zoneIds,
            arrivalCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput({ timePeriod: 'all_day', dayPeriod: 'all' }),
          }),
        );
      }

      setFlows(flows);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setFlows([]);
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.origin_destination" />
      <Paper
        header={
          <Box alignItems="center" display="flex" gap={2} justifyContent="space-between">
            <FormControl margin="none">
              <Select
                onChange={({ target: { value } }) => setH3Resolution(value as TResolution)}
                size="small"
                value={h3Resolution}
              >
                {([7, 8, 9, 'cyclabilityZones'] as const).map((resolution) => {
                  if (resolution === 'cyclabilityZones') {
                    return (
                      <MenuItem key={resolution} value={resolution}>
                        {t(`commons.administrative_levels.city_other`)}
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem key={resolution} value={resolution}>
                      Niveau {resolution}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <PeriodForm
              disableComparison
              disablePadding
              disablePeriodTypeChange
              customPeriodTypes={{ defaultPeriods, enabledTypes: ['month'] }}
              {...period}
            />
          </Box>
        }
      >
        <OriginDestinationChart {...context} />
      </Paper>
    </Box>
  );
}

export default OriginDestinationForm;
