import { IsochroneService, useAmplitudeTracker, useFileSaver } from '@geovelo-frontends/commons';
import { ShareOutlined } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import IsochronesForm from './form';
import PreviewDialog from './preview-dialog';

function IsochronesTab(): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [downloading, setDownloading] = useState(false);
  const [previewDialogOpen, openPreviewDialog] = useState(false);
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const {
    isochrones: { data, departures, durations, eBikeEnabled },
  } = context;
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();

  async function handleShpDownload() {
    setDownloading(true);
    trackEvent('File Downloaded', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      file: 'Isochrones',
    });

    try {
      const locations = departures.map(
        ({
          address,
          point: {
            coordinates: [longitude, latitude],
          },
        }) => ({
          title: address,
          latitude,
          longitude,
        }),
      );

      let _durations: number[] = [];
      Object.values(durations).forEach((value) => {
        if (typeof value === 'number' && value > 0) _durations.push(value * 60);
      });
      _durations = [...new Set(_durations)];

      const blob = await IsochroneService.getIsochronesFile({
        durations: _durations,
        locations,
      });

      downloadBlob(`isochrones.zip`, blob);
    } catch {
      enqueueSnackbar(t('cycling-insights.facilities.isochrones.server_error'), {
        variant: 'error',
      });
    }
    setDownloading(false);
    setMenuAnchorEl(null);
  }

  return (
    <>
      <PageContentLayout
        leftPanel={<IsochronesForm {...context} />}
        rightPanel={
          <Button
            color="primary"
            disabled={!data}
            onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
            startIcon={<ShareOutlined />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.export" />
          </Button>
        }
      />
      <Menu
        anchorEl={menuAnchorEl}
        id="export-menu"
        MenuListProps={{ 'aria-labelledby': 'export-menu-button' }}
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
      >
        <MenuItem disabled={downloading} key="shp" onClick={handleShpDownload} value="shp">
          Shapefile
        </MenuItem>
        <MenuItem
          key="png"
          onClick={() => {
            openPreviewDialog(true);
            setMenuAnchorEl(null);
          }}
          value="png"
        >
          PNG
        </MenuItem>
      </Menu>
      <PreviewDialog
        eBikeEnabled={eBikeEnabled}
        onClose={() => openPreviewDialog(false)}
        open={previewDialogOpen}
      />
    </>
  );
}

export default IsochronesTab;
