import {
  CyclabilityZone,
  CyclabilityZoneService,
  Flow,
  OriginDestinationService,
  TDayPeriod,
  TTimePeriod,
  currentMonth,
  prevMonth,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Close, InfoOutlined, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import PeriodForm from '../../../../components/form/period';
import ArrivalIcon from '../../../../components/icons/arrival';
import DepartureIcon from '../../../../components/icons/departure';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import useOriginDestinationFlows from '../../../../hooks/map/old-origin-destination-flows';
import { TOutletContext } from '../../../../layouts/page/container';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';
import { IBicycleObservatoryPageContext } from '../../context';
import OriginDestinationChart from '../old-origin-destination/chart';
import PreviewDialog from '../old-origin-destination/preview-dialog';

const periodProps: {
  all: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
  work: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
  leisure: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
} = {
  all: [{ timePeriod: 'all_day', dayPeriod: 'all' }],
  work: [{ timePeriod: 'peak', dayPeriod: 'week' }],
  leisure: [
    { timePeriod: 'off_peak', dayPeriod: 'week' },
    { timePeriod: 'all_day', dayPeriod: 'weekend' },
  ],
};

function OriginDestinationAnalysisForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    defaultPeriods,
    period,
    header: { setPrevButtonClick, setTitle, setActions },
    oldOriginDestination: {
      canvasRef,
      zones,
      externalZones,
      flows,
      currentRange,
      selectedZoneId,
      setCurrentRange,
      setZones,
      setExternalZones,
      setFlows,
      setBounds,
      selectZoneId,
    },
    loading,
    setLoading,
  } = context;
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
    user: { current: currentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { cancellablePromise: cancellableListPromise, cancelPromises: cancelListPromises } =
    useCancellablePromise();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    clear: clearLayers,
  } = useOriginDestinationFlows(currentMap, {
    setCyclabilityZoneId: (id) => {
      selectZoneId(selectedZoneId !== id ? id : null);
    },
  });
  const [rankingType, setRankingType] = useState<'all' | 'work' | 'leisure'>('all');
  const [flowsCount, setFlowsCount] = useState<number>();
  const [listFlows, setListFlows] = useState<Flow[]>();
  const [isSetting, setting] = useState<boolean>(true);
  const [arrivalZone, setArrivalZone] = useState<CyclabilityZone>();
  const [departureZone, setDepartureZone] = useState<CyclabilityZone>();
  const [previewDialogOpen, openPreviewDialog] = useState(false);
  const [originsMenuAnchorEl, setOriginsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [destinationsMenuAnchorEl, setDestinationsMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [selectedOrigins, selectOrigins] = useState<(CyclabilityZone | 'external')[]>();
  const [selectedDestinations, selectDestinations] = useState<(CyclabilityZone | 'external')[]>();
  const navigate = useNavigate();

  useEffect(() => {
    selectZoneId(null);
    getZones();

    return () => {
      cancelPromises();
      cancelListPromises();
      setZones(undefined);
      setExternalZones(undefined);
      setFlows(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
      setLoading(false);
      setPrevButtonClick(undefined);
      setTitle(undefined);
    };
  }, []);

  useEffect(() => {
    if (isSetting) {
      setPrevButtonClick(() => () => navigate('../origin-destination'));
      setTitle(
        <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.origin_destination_analysis" />,
      );
      setActions(undefined);
    } else {
      setPrevButtonClick(undefined);
      setTitle(<Trans i18nKey="commons.result" />);
      setActions(
        <Tooltip title={<Trans i18nKey="commons.actions.go_back" />}>
          <IconButton color="primary" component={Link} size="small" to="./origin-destination">
            <Close />
          </IconButton>
        </Tooltip>,
      );
    }
  }, [isSetting]);

  useEffect(() => {
    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (currentMap && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [currentMap, canvasRef.current]);

  useEffect(() => {
    getListData();
  }, [rankingType, period.values]);

  useEffect(() => {
    if (zones && !isSetting) getData();
  }, [period.values]);

  useEffect(() => {
    selectOrigins(zones ? [...zones, 'external'] : undefined);
    selectDestinations(zones ? [...zones, 'external'] : undefined);
  }, [zones]);

  useEffect(() => {
    if (layersInitialized) {
      updateLayers(zones, externalZones, flows, currentRange, selectedZoneId);
    }
  }, [layersInitialized, externalZones, flows, currentRange, selectedZoneId]);

  useEffect(() => {
    setFlowsCount(
      flows
        ?.filter(({ origin, destination }) => {
          if (selectedZoneId) return origin === selectedZoneId || destination === selectedZoneId;
          else return true;
        })
        .reduce((res, { count }) => (res += count), 0),
    );
  }, [flows, selectedZoneId]);

  async function getZones() {
    if (!currentPartner) return;
    try {
      const { zones } = await CyclabilityZoneService.getZones({
        administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
        partnerCode: currentPartner.code,
        considerLivingStreets: true,
        rowsPerPage: 100,
        query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
      });
      setZones(zones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  async function getData() {
    cancelPromises();
    setFlows(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);

    if (!currentPartner || !zones) return;

    const {
      values: { current: currentPeriod, timePeriod, dayPeriod },
    } = period;

    if (!timePeriod || !dayPeriod) return;

    let cancelled = false;
    setLoading(true);

    try {
      const zoneIds = zones.map(({ id }) => id);
      const props = { timePeriod, dayPeriod };

      const departureCyclabilityZoneIds: number[] = [];
      const arrivalCyclabilityZoneIds: number[] = [];
      selectedOrigins?.map((zone) => {
        if (zone !== 'external') departureCyclabilityZoneIds.push(zone.id);
      });
      selectedDestinations?.map((zone) => {
        if (zone !== 'external') arrivalCyclabilityZoneIds.push(zone.id);
      });
      let flows = await cancellablePromise(
        OriginDestinationService.getFlows({
          period: currentPeriod.toIPeriod(),
          departureCyclabilityZoneIds,
          arrivalCyclabilityZoneIds,
          ...toOriginDestinationInput(props),
        }),
      );

      if (departureZone && arrivalZone)
        flows = flows.filter(
          ({ origin, destination }) =>
            origin === departureZone.id && destination === arrivalZone.id,
        );

      const externalZonesIds = flows.reduce<number[]>((res, { origin, destination }) => {
        if (!zoneIds.includes(origin) && !res.includes(origin)) res.push(origin);
        if (!zoneIds.includes(destination) && !res.includes(destination)) res.push(destination);
        return res;
      }, []);

      if (currentUser?.isGeovelo) {
        const nbPages = Math.ceil(externalZonesIds.length / 100);
        const _externalZones = (
          await cancellablePromise(
            Promise.all(
              new Array(nbPages).fill(null).map((_, pageIndex) =>
                CyclabilityZoneService.getZones({
                  administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
                  considerLivingStreets: true,
                  ids: externalZonesIds.slice(pageIndex * 100, (pageIndex + 1) * 100),
                  rowsPerPage: 100,
                  query:
                    '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
                }),
              ),
            ),
          )
        ).flatMap(({ zones }) => zones);

        setExternalZones(_externalZones);
      } else setExternalZones([]);

      setBounds({ min: 0, max: flows.length });
      setCurrentRange([0, flows.length]);
      setFlows(
        flows.filter(
          ({ origin, destination, count }) =>
            count > 1 &&
            (selectedOrigins?.find((zone) => zone !== 'external' && zone.id === origin) ||
              (selectedOrigins?.find((zone) => zone === 'external') &&
                !zones.find((zone) => zone.id === origin))) &&
            (selectedDestinations?.find((zone) => zone !== 'external' && zone.id === destination) ||
              (selectedDestinations?.find((zone) => zone === 'external') &&
                !zones.find((zone) => zone.id === destination))),
        ),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setBounds({ min: 0, max: 1 });
        setCurrentRange([0, 1]);
        setFlows([]);
      } else cancelled = true;
    }

    if (!cancelled) setLoading(false);
  }

  async function getListData() {
    cancelListPromises();
    setListFlows(undefined);

    if (!currentPartner || !zones || rankingType === 'all') return;

    const {
      values: { current: currentPeriod },
    } = period;

    try {
      let flows;

      const departureCyclabilityZoneIds: number[] = [];
      const arrivalCyclabilityZoneIds: number[] = [];
      selectedOrigins?.map((zone) => {
        if (zone !== 'external') departureCyclabilityZoneIds.push(zone.id);
      });
      selectedDestinations?.map((zone) => {
        if (zone !== 'external') arrivalCyclabilityZoneIds.push(zone.id);
      });

      if (rankingType === 'leisure') {
        const [outgoingFlows, weekEndOutgoingFlows] = await cancellableListPromise(
          Promise.all([
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][0]),
            }),
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][1]),
            }),
          ]),
        );
        weekEndOutgoingFlows.forEach((weekEndFlow) => {
          const flow = outgoingFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else outgoingFlows.push(weekEndFlow);
        });

        const outgoingFlowsMap = outgoingFlows.reduce<{
          [key: number]: { [key: number]: true };
        }>((res, { origin, destination }) => {
          if (res[origin]) res[origin][destination] = true;
          else res[origin] = { [destination]: true };

          return res;
        }, {});

        const [incomingFlows, weekEndIncomingFlows] = await cancellableListPromise(
          Promise.all([
            OriginDestinationService.getFlows({
              startId: outgoingFlows.length,
              period: currentPeriod.toIPeriod(),
              arrivalCyclabilityZoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][0]),
            }),
            OriginDestinationService.getFlows({
              startId: outgoingFlows.length,
              period: currentPeriod.toIPeriod(),
              arrivalCyclabilityZoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][1]),
            }),
          ]),
        );
        weekEndIncomingFlows.forEach((weekEndFlow) => {
          const flow = incomingFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else incomingFlows.push(weekEndFlow);
        });

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      } else {
        const outgoingFlows = await cancellableListPromise(
          OriginDestinationService.getFlows({
            period: currentPeriod.toIPeriod(),
            departureCyclabilityZoneIds,
            ...toOriginDestinationInput(periodProps[rankingType][0]),
          }),
        );

        const outgoingFlowsMap = outgoingFlows.reduce<{ [key: number]: { [key: number]: true } }>(
          (res, { origin, destination }) => {
            if (res[origin]) res[origin][destination] = true;
            else res[origin] = { [destination]: true };

            return res;
          },
          {},
        );

        const incomingFlows = await cancellableListPromise(
          OriginDestinationService.getFlows({
            startId: outgoingFlows.length,
            period: currentPeriod.toIPeriod(),
            arrivalCyclabilityZoneIds,
            ...toOriginDestinationInput(periodProps[rankingType][0]),
          }),
        );

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      }
      setListFlows(
        flows.filter(
          ({ origin, destination }) =>
            (selectedOrigins?.find((zone) => zone !== 'external' && zone.id === origin) ||
              (selectedOrigins?.find((zone) => zone === 'external') &&
                !zones.find((zone) => zone.id === origin))) &&
            (selectedDestinations?.find((zone) => zone !== 'external' && zone.id === destination) ||
              (selectedDestinations?.find((zone) => zone === 'external') &&
                !zones.find((zone) => zone.id === destination))),
        ),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setListFlows([]);
      }
    }
  }

  function resetFilters() {
    period.setValues({
      current: currentMonth,
      prev: prevMonth,
      timePeriod: 'all_day',
      dayPeriod: 'all',
    });
    setArrivalZone(undefined);
    setDepartureZone(undefined);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      justifyContent="space-between"
      minHeight="100%"
    >
      {isSetting ? (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={3}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gap={6} marginTop={3}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography fontWeight={600} variant="subtitle1">
                <Trans i18nKey="commons.period" />
              </Typography>
              <PeriodForm
                disableComparison
                disablePadding
                enableDayPeriods
                enableTimePeriods
                {...period}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography fontWeight={600} variant="subtitle1">
                <Trans i18nKey="commons.zones" />
              </Typography>
              <Box alignItems="center" display="flex" gap={2}>
                <Button
                  color="inherit"
                  disabled={!zones || !selectedOrigins}
                  endIcon={<DepartureIcon />}
                  onClick={({ currentTarget }) => setOriginsMenuAnchorEl(currentTarget)}
                  sx={{
                    border: '1px solid #C7CEDC',
                    padding: '5px 15px',
                    textTransform: 'initial',
                  }}
                  variant="outlined"
                >
                  <Trans i18nKey="cycling-insights.usage.origin_destination.origin" />
                </Button>
                <Menu
                  keepMounted
                  anchorEl={originsMenuAnchorEl}
                  id="origins-menu"
                  MenuListProps={{ style: { width: 250 } }}
                  onClose={() => setOriginsMenuAnchorEl(null)}
                  open={Boolean(originsMenuAnchorEl)}
                >
                  <MenuItem
                    dense
                    onClick={() =>
                      zones &&
                      selectedOrigins &&
                      selectOrigins(
                        selectedOrigins.length === zones.length + 1 ? [] : [...zones, 'external'],
                      )
                    }
                  >
                    <Checkbox
                      checked={
                        (selectedOrigins && zones && selectedOrigins.length === zones.length + 1) ||
                        false
                      }
                      indeterminate={
                        selectedOrigins &&
                        zones &&
                        selectedOrigins.length > 0 &&
                        selectedOrigins.length !== zones.length + 1
                      }
                      style={{ padding: 4, marginRight: 8 }}
                    />
                    <ListItemText primary={<Trans i18nKey="commons.all" />} />
                  </MenuItem>
                  {!!zones && !!selectedOrigins && (
                    <>
                      {[...zones]
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((zone) => {
                          const checked = selectedOrigins.indexOf(zone) > -1;

                          return (
                            <MenuItem
                              dense
                              key={zone.id}
                              onClick={() =>
                                checked
                                  ? selectOrigins(selectedOrigins.filter((key) => key !== zone))
                                  : selectOrigins([...selectedOrigins, zone])
                              }
                              value={zone.id}
                            >
                              <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                              <ListItemText primary={zone.name} />
                            </MenuItem>
                          );
                        })}
                      <MenuItem
                        dense
                        key="external"
                        onClick={() =>
                          selectedOrigins.indexOf('external') > -1
                            ? selectOrigins(selectedOrigins.filter((key) => key !== 'external'))
                            : selectOrigins([...selectedOrigins, 'external'])
                        }
                        value="external"
                      >
                        <Checkbox
                          checked={selectedOrigins.indexOf('external') > -1}
                          style={{ padding: 4, marginRight: 8 }}
                        />
                        <ListItemText
                          primary={t('cycling-insights.usage.origin_destination.external_zones')}
                        />
                      </MenuItem>
                    </>
                  )}
                </Menu>
                <Button
                  color="inherit"
                  disabled={!zones || !selectedDestinations}
                  endIcon={<ArrivalIcon />}
                  onClick={({ currentTarget }) => setDestinationsMenuAnchorEl(currentTarget)}
                  sx={{
                    border: '1px solid #C7CEDC',
                    padding: '5px 15px',
                    textTransform: 'initial',
                  }}
                  variant="outlined"
                >
                  <Trans i18nKey="cycling-insights.usage.origin_destination.destination" />
                </Button>
                <Menu
                  keepMounted
                  anchorEl={destinationsMenuAnchorEl}
                  id="destinations-menu"
                  MenuListProps={{ style: { width: 250 } }}
                  onClose={() => setDestinationsMenuAnchorEl(null)}
                  open={Boolean(destinationsMenuAnchorEl)}
                >
                  <MenuItem
                    dense
                    onClick={() =>
                      zones &&
                      selectedDestinations &&
                      selectDestinations(
                        selectedDestinations.length === zones.length + 1
                          ? []
                          : [...zones, 'external'],
                      )
                    }
                  >
                    <Checkbox
                      checked={
                        (selectedDestinations &&
                          zones &&
                          selectedDestinations.length === zones.length + 1) ||
                        false
                      }
                      indeterminate={
                        selectedDestinations &&
                        zones &&
                        selectedDestinations.length > 0 &&
                        selectedDestinations.length !== zones.length + 1
                      }
                      style={{ padding: 4, marginRight: 8 }}
                    />
                    <ListItemText primary={<Trans i18nKey="commons.all" />} />
                  </MenuItem>
                  {!!zones && !!selectedDestinations && (
                    <>
                      {[...zones]
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((zone) => {
                          const checked = selectedDestinations.indexOf(zone) > -1;

                          return (
                            <MenuItem
                              dense
                              key={zone.id}
                              onClick={() =>
                                checked
                                  ? selectDestinations(
                                      selectedDestinations.filter((key) => key !== zone),
                                    )
                                  : selectDestinations([...selectedDestinations, zone])
                              }
                              value={zone.id}
                            >
                              <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                              <ListItemText primary={zone.name} />
                            </MenuItem>
                          );
                        })}
                      <MenuItem
                        dense
                        key="external"
                        onClick={() =>
                          selectedDestinations.indexOf('external') > -1
                            ? selectDestinations(
                                selectedDestinations.filter((key) => key !== 'external'),
                              )
                            : selectDestinations([...selectedDestinations, 'external'])
                        }
                        value="external"
                      >
                        <Checkbox
                          checked={selectedDestinations.indexOf('external') > -1}
                          style={{ padding: 4, marginRight: 8 }}
                        />
                        <ListItemText
                          primary={t('cycling-insights.usage.origin_destination.external_zones')}
                        />
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box
              alignItems="center"
              bgcolor="#FFF4D8"
              borderRadius={2}
              display="flex"
              gap="12px"
              padding={2}
            >
              <InfoOutlined fontSize="small" sx={{ color: '#3F2813' }} />
              <Typography color="#3F2813" fontSize="0.75rem" variant="body2">
                <Trans i18nKey="cycling-insights.bicycle_observatory.long_analysis" />
              </Typography>
            </Box>
            <Box display="flex" gap={2} justifyContent="flex-end">
              <StyledButton
                disableElevation
                color="primary"
                onClick={resetFilters}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.reset" />
              </StyledButton>
              <StyledButton
                disableElevation
                color="primary"
                onClick={() => {
                  getData();
                  setting(false);
                }}
                variant="contained"
              >
                <Trans i18nKey="commons.actions.analyze" />
              </StyledButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={3}>
            <Paper
              header={
                <PeriodForm
                  disableComparison
                  disablePadding
                  disablePeriodTypeChange
                  customPeriodTypes={{ defaultPeriods, enabledTypes: [period.values.current.type] }}
                  {...period}
                />
              }
            >
              <OriginDestinationChart {...context} />
            </Paper>
            <Ranking
              disableProgression
              hasPercentageBar
              action={
                <Button
                  component={Link}
                  endIcon={<KeyboardArrowRight />}
                  to="../origin-destination-list"
                  variant="text"
                >
                  <Trans i18nKey="cycling-insights.usage.origin_destination.all_trips" />
                </Button>
              }
              data={
                zones &&
                (rankingType === 'all' ? flows : listFlows)
                  ?.filter(({ origin, destination }) => {
                    if (selectedZoneId !== null)
                      return (
                        (origin === selectedZoneId || destination === selectedZoneId) &&
                        zones.find(({ id }) => id === origin) &&
                        zones.find(({ id }) => id === destination)
                      );
                    else
                      return (
                        zones.find(({ id }) => id === origin) &&
                        zones.find(({ id }) => id === destination)
                      );
                  })
                  .sort((a, b) => b.count - a.count)
                  .slice(0, 5)
                  .map(({ id, count, origin, destination }) => {
                    return {
                      id: id,
                      title:
                        zones.find(({ id }) => id === origin)?.name +
                        ' > ' +
                        zones.find(({ id }) => id === destination)?.name,
                      value: count,
                    };
                  })
              }
              subtitle={
                <ToggleButtonGroup
                  exclusive
                  color="primary"
                  onChange={(_, value) => setRankingType(value)}
                  sx={{ marginTop: '16px' }}
                  value={rankingType}
                >
                  <ToggleButton value="all">
                    <Typography fontSize="0.875rem" textTransform="none">
                      <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.all" />
                    </Typography>
                  </ToggleButton>
                  <ToggleButton value="work">
                    <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
                      <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.work" />
                    </Typography>
                    <Tooltip
                      title={
                        <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.work_tooltip" />
                      }
                    >
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="leisure">
                    <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
                      <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.leisure" />
                    </Typography>
                    <Tooltip
                      title={
                        <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.leisure_tooltip" />
                      }
                    >
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              }
              title="cycling-insights.usage.origin_destination.ranking.title"
              total={flowsCount}
            />
          </Box>
          <Box display="flex" gap={2} justifyContent="flex-end">
            <StyledButton
              disableElevation
              color="primary"
              disabled={loading}
              onClick={() => openPreviewDialog(true)}
              variant="outlined"
            >
              <Trans i18nKey="commons.actions.download" />
            </StyledButton>
            <StyledButton
              disableElevation
              color="primary"
              disabled={loading}
              onClick={() => {
                setFlows(undefined);
                setBounds(undefined);
                setCurrentRange(undefined);
                setting(true);
              }}
              variant="contained"
            >
              <Trans i18nKey="commons.actions.restart_analyze" />
            </StyledButton>
          </Box>
        </>
      )}
      <PreviewDialog onClose={() => openPreviewDialog(false)} open={previewDialogOpen} />
    </Box>
  );
}

const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 54px;
  && {
    padding: 0 24px;
    font-weight: 700;
  }
`;

export default OriginDestinationAnalysisForm;
