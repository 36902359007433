import { CyclabilityZone, Flow } from '@geovelo-frontends/commons';
import { LinearProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { TRow } from '../components/table';

import usePaginatedTable from './table/paginated';
import useSortableTable from './table/sortable';

function useOriginDestinationTable<TKey extends string>({
  defaultOrderBy,
  flows,
  zones,
  externalZones,
}: {
  defaultOrderBy: TKey;
  flows?: Flow[];
  zones?: CyclabilityZone[];
  externalZones?: CyclabilityZone[];
}) {
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable(10);
  const { orderBy, order, sort, onSortRequest } = useSortableTable<number, TKey>(
    defaultOrderBy,
    'desc',
    {
      setPage,
    },
  );
  const totalRidesRef = useRef<number>();

  useEffect(() => {
    if (!flows) {
      setRows(undefined);
    } else {
      totalRidesRef.current = flows.reduce<number>((res, { count }) => (res += count), 0);
      const data = flows.map(parseFlow);
      const _rows = sort(data, 'count' as TKey);
      setRows(_rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }, [flows, orderBy, order, page, rowsPerPage]);

  function parseFlow(flow: Flow) {
    const { id, origin, destination, count } = flow;

    return {
      key: id,
      cells: {
        origin: {
          value:
            (
              zones?.find(({ id }) => origin === id) ||
              externalZones?.find(({ id }) => origin === id)
            )?.name || '',
          format: (value: string) => (
            <div
              style={{
                width: '130px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </div>
          ),
        },
        destination: {
          value:
            (
              zones?.find(({ id }) => destination === id) ||
              externalZones?.find(({ id }) => destination === id)
            )?.name || '',
          format: (value: string) => (
            <div
              style={{
                width: '130px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </div>
          ),
        },
        percentage: {
          value: totalRidesRef.current ? (count * 100) / totalRidesRef.current : 0,
          format: (value: number) => <StyledLinearProgress value={value} variant="determinate" />,
        },
        count: { value: count },
      },
    };
  }

  return {
    orderBy,
    order,
    page,
    rowsPerPage,
    rows,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
  };
}

const StyledLinearProgress = styled(LinearProgress)`
  && {
    width: 60px;
    min-width: 60px;
    height: 8px;
    border-radius: 8px;
    background-color: #e3e7ee;

    > .MuiLinearProgress-bar {
      background-color: #3e7bdf;
      border-radius: 8px;
    }
  }
`;

export default useOriginDestinationTable;
