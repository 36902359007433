import {
  Period,
  StatsService,
  StoppingAreaService,
  TStoppingAreasFeatureCollection,
  prevMonth,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { AccessTimeOutlined, PanToolOutlined, Place } from '@mui/icons-material';
import { Box, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import illuFlux from '../../../../assets/images/illu-flux.png';
import { TColorCollection } from '../../../../components/color-legend';
import ColoredStats from '../../../../components/colored-stats';
import PeriodForm from '../../../../components/form/period';
import LinkCard from '../../../../components/link-card';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useAverageSpeeds from '../../../../hooks/map/average-speeds';
import useStoppingAreas, { defaultColors } from '../../../../hooks/map/stopping-areas';
import usePeriod from '../../../../hooks/period';
import useSectionsStats from '../../../../hooks/sections-stats';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';

import FluidityChart from './chart';

const clusterLevel2MinZoom = 16;
export const colors: TColorCollection = [
  { value: '#F56B84', min: 0, max: 13 },
  { value: '#FFD978', min: 13, max: 15 },
  { value: '#46CE9D', min: 15, max: Infinity },
];

function FluidityForm(context: IBicycleObservatoryPageContext & TOutletContext): JSX.Element {
  const {
    defaultPeriods,
    period,
    averageSpeeds,
    stoppingAreas: {
      data: stoppingAreasData,
      bounds: stoppingAreasBounds,
      secondaryBounds: stoppingAreasSecondaryBounds,
      currentRange: stoppingAreasCurrentRange,
      secondaryRange: stoppingAreasSecondaryRange,
      setData: setStoppingAreasData,
      setQuartiles: setStoppingAreasQuartiles,
      setBounds: setStoppingAreasBounds,
      setSecondaryBounds: setStoppingAreasSecondaryBounds,
      setCurrentRange: setStoppingAreasCurrentRange,
      setSecondaryRange: setStoppingAreasSecondaryRange,
    },
    setLoading,
  } = context;
  const {
    data,
    bounds,
    currentRange,
    globalAverageSpeed,
    prevGlobalAverageSpeed,
    sectionsComparedToAverage,
    setQuartiles,
    setTotalDistanceTraveled,
    totalDistanceTraveled,
  } = averageSpeeds;
  const {
    map: { current: currentMap, baseLayer, zoom, stoppingAreasShowed, averageSpeedsShowed },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useAverageSpeeds(currentMap, period);
  const {
    initialized: stoppingAreasLayersInitialized,
    init: initStoppingAreasLayers,
    update: updateStoppingAreasLayers,
    destroy: destroyStoppingAreasLayers,
  } = useStoppingAreas(currentMap);
  const [initialized, setInitialized] = useState(false);
  const [lastYearDistance, setLastYearDistance] = useState<number>();
  const { timeoutRef, secondaryBounds } = useSectionsStats({
    hasChartComparison: true,
    primaryCriterion: 'averageSpeed',
    initialized: layersInitialized,
    period,
    ...averageSpeeds,
    setLoading,
  });
  const stoppingAreasTimeoutRef = useRef<NodeJS.Timeout>();
  const [allData, setAllData] = useState<TStoppingAreasFeatureCollection | null>();
  const [lastYearAllData, setLastYearAllData] = useState<TStoppingAreasFeatureCollection | null>();
  const [lastYearData, setLastYearData] = useState<TStoppingAreasFeatureCollection | null>();
  const [prevZoom, setPrevZoom] = useState(zoom);
  const [stoppingTimeByKm, setStoppingTimeByKm] = useState<number>();
  const [lastYearStoppingTimeByKm, setLastYearStoppingTimeByKm] = useState<number>();
  const lastYear = useRef<Period>(period.values.current.clone());
  const [rankingType, setRankingType] = useState<'number' | 'duration'>('number');
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { getTitle: getPeriodTitle } = usePeriod();
  const { toTime } = useUnits();
  const [cancelled, setCancelled] = useState<boolean>();
  const [speedsDataDisplayed, displaySpeedsData] = useState<boolean>(false);
  const [stopsDataDisplayed, displayStopsData] = useState<boolean>(false);

  useEffect(() => {
    period.enableComparison?.(false);
    period.setValues({ ...period.values, timePeriod: 'all_day', dayPeriod: 'all' });
    setInitialized(true);

    return () => {
      cancelPromises();
      setAllData(undefined);
      setStoppingAreasData(undefined);
      setStoppingAreasQuartiles(undefined);
      setStoppingAreasBounds(undefined);
      setStoppingAreasSecondaryBounds(undefined);
      setStoppingAreasCurrentRange(undefined);
      setStoppingAreasSecondaryRange(undefined);
      setLoading(false);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (stoppingAreasTimeoutRef.current) clearTimeout(stoppingAreasTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentMap) {
      initLayers();
      initStoppingAreasLayers();
    }

    return () => {
      destroyLayers();
      destroyStoppingAreasLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (cancelled === false && stopsDataDisplayed && speedsDataDisplayed) setLoading(false);
  }, [cancelled, stopsDataDisplayed, speedsDataDisplayed]);

  useEffect(() => {
    if (initialized) {
      lastYear.current = period.values.current.clone();
      lastYear.current.from.add(-1, 'year');
      lastYear.current.to.add(-1, 'year');
      getData();
      getDistancesTravelled();
    }

    return () => {
      cancelPromises();
      setAllData(undefined);
      setStoppingAreasData(undefined);
      setStoppingAreasQuartiles(undefined);
      setStoppingAreasBounds(undefined);
      setStoppingAreasSecondaryBounds(undefined);
      setStoppingAreasCurrentRange(undefined);
      setStoppingAreasSecondaryRange(undefined);
      setTotalDistanceTraveled(undefined);
      setLastYearDistance(undefined);
    };
  }, [initialized, period.values]);

  useEffect(() => {
    if (
      zoom !== undefined &&
      (!prevZoom ||
        (prevZoom < clusterLevel2MinZoom && zoom >= clusterLevel2MinZoom) ||
        (prevZoom >= clusterLevel2MinZoom && zoom < clusterLevel2MinZoom))
    ) {
      update();
    }

    setPrevZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    update();
  }, [allData]);

  useEffect(() => {
    if (totalDistanceTraveled)
      setStoppingTimeByKm(
        (stoppingAreasData?.features.reduce((res, { properties: { nbStops, meanDuration } }) => {
          return (res += nbStops * meanDuration);
        }, 0) || 0) / totalDistanceTraveled,
      );
    else setStoppingTimeByKm(undefined);
  }, [stoppingAreasData, totalDistanceTraveled]);

  useEffect(() => {
    if (lastYearDistance)
      setLastYearStoppingTimeByKm(
        (lastYearData?.features.reduce((res, { properties: { nbStops, meanDuration } }) => {
          return (res += nbStops * meanDuration);
        }, 0) || 0) / lastYearDistance,
      );
  }, [lastYearData, lastYearDistance]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    const { comparisonEnabled } = period;

    if (averageSpeedsShowed && data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        if (!comparisonEnabled && data.features.length > 0 && globalAverageSpeed) {
          const { max } = bounds;
          setQuartiles([0, 13, 15, max]);
        }
        updateLayers(data, {
          colors,
          comparisonEnabled,
          currentRange,
          primaryBounds: bounds,
          secondaryBounds,
        });
        displaySpeedsData(true);
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          comparisonEnabled: period.comparisonEnabled,
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
          secondaryBounds,
        },
      );
      displaySpeedsData(true);
    }
  }, [baseLayer, layersInitialized, data, currentRange, averageSpeedsShowed]);

  useEffect(() => {
    if (stoppingAreasTimeoutRef.current) clearTimeout(stoppingAreasTimeoutRef.current);
    if (!stoppingAreasLayersInitialized) return;

    if (
      stoppingAreasData &&
      stoppingAreasBounds &&
      stoppingAreasSecondaryBounds &&
      stoppingAreasCurrentRange &&
      stoppingAreasSecondaryRange &&
      stoppingAreasShowed
    ) {
      stoppingAreasTimeoutRef.current = setTimeout(() => {
        const { min, max } = stoppingAreasBounds;
        const durations = stoppingAreasData.features
          .map(({ properties: { medianDuration } }) => medianDuration)
          .filter((duration) => duration >= min && duration <= max)
          .sort((a, b) => a - b);

        const _quartiles = [
          ...new Set([
            min,
            ...defaultColors
              .slice(0, Math.min(defaultColors.length - 1, durations.length - 1))
              .map((_, index) =>
                Math.round(
                  durations[Math.round(durations.length * ((index + 1) / defaultColors.length))] ||
                    0,
                ),
              ),
            max,
          ]),
        ];

        setStoppingAreasQuartiles(_quartiles);

        const colors = baseLayer === 'dark' ? defaultColors : [...defaultColors].reverse();
        updateStoppingAreasLayers(stoppingAreasData, {
          colors: colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
            value,
            min: _quartiles?.[index],
            max: _quartiles?.[index + 1],
          })),
          currentRange: stoppingAreasCurrentRange,
          primaryBounds: stoppingAreasBounds,
          secondaryBounds: stoppingAreasSecondaryBounds,
          secondaryRange: stoppingAreasSecondaryRange,
        });
        displayStopsData(true);
      }, 300);
    } else {
      updateStoppingAreasLayers(
        { type: 'FeatureCollection', features: [] },
        {
          primaryBounds: { min: 0, max: 1 },
          currentRange: [0, 1],
          secondaryBounds: { min: 0, max: 1 },
          secondaryRange: [0, 1],
        },
      );
      displayStopsData(true);
    }
  }, [
    baseLayer,
    stoppingAreasLayersInitialized,
    stoppingAreasData,
    stoppingAreasCurrentRange,
    stoppingAreasSecondaryRange,
    stoppingAreasShowed,
  ]);

  async function getData() {
    if (!currentPartner) return;

    const {
      values: { current: currentPeriod, timePeriod, dayPeriod },
    } = period;

    if (!timePeriod || !dayPeriod) return;

    setLoading(true);

    displaySpeedsData(false);
    displayStopsData(false);

    try {
      const [_data, _lastYearData] = await cancellablePromise(
        Promise.all([
          StoppingAreaService.getStoppingAreas({
            partnerId: currentPartner.id,
            period: currentPeriod.toIPeriod(),
            dayPeriod,
            timePeriod,
          }),
          StoppingAreaService.getStoppingAreas({
            partnerId: currentPartner.id,
            period: lastYear.current.toIPeriod(),
            dayPeriod,
            timePeriod,
          }),
        ]),
      );

      if (_data.features.length === 0) throw new Error('no data');

      setLastYearAllData(_lastYearData);
      setAllData(_data);
      setCancelled(false);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getPeriodTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        setStoppingAreasBounds({ min: 0, max: 1 });
        setStoppingAreasSecondaryBounds(undefined);
        setStoppingAreasQuartiles([0, 1]);
        setAllData(null);
        setStoppingAreasData(null);
      } else setCancelled(true);
    }
  }

  function update() {
    if (zoom !== undefined && allData) {
      let max = 0;
      let secondaryMax = 0;

      const filteredData: TStoppingAreasFeatureCollection = {
        ...allData,
        features: allData.features.filter(
          ({ properties: { clusterLevel } }) =>
            clusterLevel === (zoom >= clusterLevel2MinZoom ? 2 : 1),
        ),
      };

      if (lastYearAllData) {
        const filteredLastYearData: TStoppingAreasFeatureCollection = {
          ...lastYearAllData,
          features:
            lastYearAllData?.features.filter(
              ({ properties: { clusterLevel } }) =>
                clusterLevel === (zoom >= clusterLevel2MinZoom ? 2 : 1),
            ) || [],
        };
        setLastYearData(filteredLastYearData);
      } else setLastYearData(lastYearAllData);

      filteredData.features.forEach(({ properties }) => {
        max = Math.max(max, properties.totalNbStops);
        secondaryMax = Math.max(secondaryMax, properties.medianDuration);
      });

      max = Math.max(Math.ceil(max), 5);
      secondaryMax = Math.ceil(secondaryMax);

      setStoppingAreasBounds({ min: 0, max });
      setStoppingAreasCurrentRange([0, max]);
      setStoppingAreasSecondaryBounds({ min: 0, max: secondaryMax });
      setStoppingAreasSecondaryRange([0, secondaryMax]);
      setStoppingAreasData(filteredData);
    } else setStoppingAreasData(allData);
    setPrevZoom(zoom);
  }

  async function getDistancesTravelled() {
    if (!currentPartner) return;

    try {
      const [currentDistances, lastYearDistances] = await Promise.all([
        StatsService.getDistancesTravelled(currentPartner.id, period.values.current.toIPeriod()),
        StatsService.getDistancesTravelled(currentPartner.id, lastYear.current.toIPeriod()),
      ]);
      setTotalDistanceTraveled(currentDistances.all);
      setLastYearDistance(lastYearDistances.all);
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.fluidity.server_error'), {
        variant: 'error',
      });
    }
  }

  const sectionsCount = sectionsComparedToAverage?.reduce((res, count) => res + count, 0);

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.fluidity" />

      <Paper
        header={
          <PeriodForm
            disableComparison
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={{ defaultPeriods, enabledTypes: ['month'] }}
            maxPeriod={moment().get('date') <= 7 ? prevMonth : undefined}
            minPeriod={
              new Period(
                'month',
                moment('2021-10').startOf('month'),
                moment('2021-10').endOf('month'),
              )
            }
            {...period}
          />
        }
      >
        <Box display="flex" gap={3} justifyContent="space-between">
          <Box border="1px solid #E3E7EE" borderRadius={4} padding={2}>
            <Typography color="#5D687E" variant="body2">
              <Trans i18nKey="commons.stats.average_speed_label" />
            </Typography>
            {globalAverageSpeed ? (
              <Typography fontSize="1.5rem" fontWeight={600}>
                {Math.round(globalAverageSpeed * 10) / 10} km/h
              </Typography>
            ) : (
              <Skeleton variant="text" width={120} />
            )}
            {!!globalAverageSpeed && !!prevGlobalAverageSpeed && (
              <Typography color="#5D687E" variant="body2">
                <span
                  style={{
                    color: globalAverageSpeed < prevGlobalAverageSpeed ? '#A42C49' : '#038B63',
                  }}
                >
                  {globalAverageSpeed < prevGlobalAverageSpeed ? '-' : '+'}{' '}
                  {Math.abs(Math.round((globalAverageSpeed - prevGlobalAverageSpeed) * 10) / 10)}{' '}
                  km/h{' '}
                </span>
                vs {lastYear.current.from.format('MMMM YYYY')}
              </Typography>
            )}
          </Box>
          <Box border="1px solid #E3E7EE" borderRadius={4} padding={2}>
            <Typography color="#5D687E" variant="body2">
              <Trans i18nKey="commons.stats.mean_stop_time_per_km" />
            </Typography>
            {stoppingTimeByKm ? (
              <Typography fontSize="1.5rem" fontWeight={600}>
                {toTime(Math.round(stoppingTimeByKm * 10000) / 10)}
              </Typography>
            ) : (
              <Skeleton variant="text" width={120} />
            )}
            {!!stoppingTimeByKm && !!lastYearStoppingTimeByKm && (
              <Typography color="#5D687E" variant="body2">
                <span
                  style={{
                    color: stoppingTimeByKm < lastYearStoppingTimeByKm ? '#038B63' : '#A42C49',
                  }}
                >
                  {stoppingTimeByKm < lastYearStoppingTimeByKm ? '-' : '+'}{' '}
                  {toTime(
                    Math.round(Math.abs((stoppingTimeByKm - lastYearStoppingTimeByKm) * 10000)) /
                      10,
                  )}{' '}
                </span>
                vs {lastYear.current.from.format('MMMM YYYY')}
              </Typography>
            )}
          </Box>
        </Box>

        <FluidityChart {...context} />
        <Box display="flex" gap={2}>
          <ColoredStats
            color="#FFEBEE"
            content={
              <Typography fontSize="0.75rem" fontWeight={400}>
                <Trans i18nKey="cycling-insights.usage.fluidity.slower_roads" />
              </Typography>
            }
            header={
              sectionsComparedToAverage && sectionsCount !== undefined ? (
                <Typography fontSize="1.125rem" fontWeight={600}>
                  {sectionsCount
                    ? ((sectionsComparedToAverage[0] / sectionsCount) * 100).toFixed(1)
                    : 0}{' '}
                  %
                </Typography>
              ) : (
                <Skeleton variant="text" width={80} />
              )
            }
          />
          <ColoredStats
            color="rgba(255, 217, 120, 0.2)"
            content={
              <Typography fontSize="0.75rem" fontWeight={400}>
                <Trans i18nKey="cycling-insights.usage.fluidity.same_speed_roads" />
              </Typography>
            }
            header={
              sectionsComparedToAverage && sectionsCount !== undefined ? (
                <Typography fontSize="1.125rem" fontWeight={600}>
                  {sectionsCount
                    ? ((sectionsComparedToAverage[1] / sectionsCount) * 100).toFixed(1)
                    : 0}{' '}
                  %
                </Typography>
              ) : (
                <Skeleton variant="text" width={80} />
              )
            }
          />
          <ColoredStats
            color="#E3F5ED"
            content={
              <Typography fontSize="0.75rem" fontWeight={400}>
                <Trans i18nKey="cycling-insights.usage.fluidity.faster_roads" />
              </Typography>
            }
            header={
              sectionsComparedToAverage && sectionsCount !== undefined ? (
                <Typography fontSize="1.125rem" fontWeight={600}>
                  {sectionsCount
                    ? ((sectionsComparedToAverage[2] / sectionsCount) * 100).toFixed(1)
                    : 0}{' '}
                  %
                </Typography>
              ) : (
                <Skeleton variant="text" width={80} />
              )
            }
          />
        </Box>
      </Paper>

      <Ranking
        disableProgression
        data={allData?.features
          .filter(
            ({ properties: { clusterLevel, totalNbStops } }) =>
              clusterLevel === 1 && totalNbStops > 5,
          )
          .sort(
            (a, b) =>
              ((rankingType === 'number'
                ? b.properties.totalNbStops
                : b.properties.medianDuration) || 0) -
              ((rankingType === 'number'
                ? a.properties.totalNbStops
                : a.properties.medianDuration) || 0),
          )
          .slice(0, 3)
          .map(({ properties: { totalNbStops, medianDuration }, id }) => {
            return {
              id: typeof id === 'string' ? parseInt(id) : id,
              title: t('cycling-insights.usage.stopping_areas.stop_title', { id }),
              value: rankingType === 'number' ? totalNbStops : medianDuration,
              secondValue: rankingType === 'number' ? medianDuration : totalNbStops,
            };
          })}
        formatData={(element) => (
          <Box alignItems="center" display="flex" gap="4px">
            <Typography fontSize="1rem" fontWeight={600}>
              {rankingType === 'number' ? (
                <Trans
                  count={element.value}
                  i18nKey="cycling-insights.usage.stopping_areas.stops"
                  values={{ count: element.value }}
                />
              ) : (
                toTime(element.value || 0, 'auto', false)
              )}
            </Typography>
            {element.secondValue && (
              <Typography fontSize="0.875rem">
                &middot;{' '}
                {rankingType === 'number' ? (
                  toTime(element.secondValue, 'auto', false)
                ) : (
                  <Trans
                    count={element.secondValue}
                    i18nKey="cycling-insights.usage.stopping_areas.stops"
                    values={{ values: element.secondValue }}
                  />
                )}
              </Typography>
            )}
          </Box>
        )}
        onClick={(id) => {
          const coordinates = allData?.features.find(({ id: areaId }) => areaId === id)?.geometry
            .coordinates;

          if (coordinates) {
            const [lng, lat] = coordinates;
            currentMap?.flyTo({
              center: { lat, lng },
              zoom: clusterLevel2MinZoom - 1,
              animate: false,
            });
          }
        }}
        startIcon={<Place sx={{ color: '#E76685' }} />}
        subtitle={
          <ToggleButtonGroup
            exclusive
            color="primary"
            onChange={(_, value) => value && setRankingType(value)}
            sx={{ marginTop: '16px' }}
            value={rankingType}
          >
            <ToggleButton value="number">
              <PanToolOutlined fontSize="small" />
              <Typography fontSize="0.875rem" marginLeft={1} textTransform="none">
                <Trans i18nKey="cycling-insights.usage.stopping_areas.stops_number" />
              </Typography>
            </ToggleButton>
            <ToggleButton value="duration">
              <AccessTimeOutlined fontSize="small" />
              <Typography fontSize="0.875rem" marginLeft={1} textTransform="none">
                <Trans i18nKey="cycling-insights.usage.stopping_areas.stop_duration" />
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        }
        title="cycling-insights.usage.fluidity.ranking.title"
      />
      <LinkCard
        description={<Trans i18nKey="cycling-insights.usage.fluidity.targeted_analysis.subtitle" />}
        icon={illuFlux}
        title={<Trans i18nKey="cycling-insights.usage.fluidity.targeted_analysis.title" />}
        to="../fluidity-analysis"
      />
    </Box>
  );
}

export default FluidityForm;
