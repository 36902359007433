import { useFileSaver } from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import OriginDestinationListForm from './form';

function OriginDestinationListTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    header: { setActions },
    period,
    oldOriginDestination: { flows, zones },
  } = context;
  const { downloadCSV } = useFileSaver();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  function handleDownload() {
    if (!flows || !zones) return;

    downloadCSV(
      `${t('cycling-insights.usage.origin_destination.all_rides')
        .replace(/ /g, '_')
        .toLowerCase()}-${period.values.current.from.format(
        'YYYY-MM-DD',
      )}_${period.values.current.to.format('YYYY-MM-DD')}.csv`,
      [
        t('cycling-insights.usage.origin_destination.origin'),
        t('cycling-insights.usage.origin_destination.destination'),
        t('commons.stats_types.journeys'),
      ],
      flows.map(({ origin, destination, count }) => [
        zones.find(({ id }) => id === origin)?.name || '',
        zones.find(({ id }) => id === destination)?.name || '',
        count,
      ]),
    );
  }

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={<Trans i18nKey="commons.actions.download" />}>
          <span>
            <IconButton
              color="primary"
              disabled={!flows}
              onClick={() => handleDownload()}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );
  }, [flows]);

  return (
    <PageContentLayout
      leftPanel={<OriginDestinationListForm {...context} />}
      main={
        <StyledCanvas
          id="origin-destination-list-deck-canvas"
          ref={context.oldOriginDestination.canvasRef}
        />
      }
    />
  );
}

const StyledCanvas = styled.canvas`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default OriginDestinationListTab;
