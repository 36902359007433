import { Deck, Layer } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import FlowMapLayer, { FlowLayerPickingInfo, PickingType } from '@flowmap.gl/core';
import { useUnits } from '@geovelo-frontends/commons';
import centroid from '@turf/centroid';
import { cellToBoundary, cellToLatLng, cellToParent, cellsToMultiPolygon } from 'h3-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import { TColorCollection } from '../../components/color-legend';
import { TOutletContext } from '../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../pages/bicycle-observatory/context';
import { TH3CellFeatureProps } from '../../pages/bicycle-observatory/models/origin-destination';

import useH3 from './h3';
import H3CellTooltip from './h3-cell-tooltip';

import { Map, Popup } from '!maplibre-gl';

type TLocation = GeoJSON.Feature<GeoJSON.Point | GeoJSON.Polygon, TH3CellFeatureProps>;

type TFlow = { id: string; origin: string; destination: string; count: number };

const colors: Array<[number, number, number]> = [
  [237, 209, 202],
  [193, 147, 157],
  [149, 100, 133],
  [100, 61, 104],
  [80, 49, 94],
];

export const originDestinationColors: TColorCollection = [
  { value: '#edd1ca' },
  { value: '#c1939d' },
  { value: '#956485' },
  { value: '#643d68' },
  { value: '#50315e' },
];

const selectedH3CellKey = 'selected-h3';

function useOriginDestinationFlows({
  context,
  map,
}: {
  map: Map | undefined | null;
  context: IBicycleObservatoryPageContext & TOutletContext;
  zoom: number | undefined;
}): {
  initialized: boolean;
  init: (canvas: HTMLCanvasElement) => void;
  clear: () => void;
  destroy: () => void;
} {
  const {
    originDestination: {
      zones,
      h3Resolution,
      h3Features,
      externalH3Features: _externalH3Features,
      selectedH3Indices,
      flows: _flows,
      setBounds,
      setCurrentRange,
      setH3Features,
      selectH3Indices,
    },
  } = context;
  const [initialized, setInitialized] = useState(false);
  const {
    map: { originDestinationZonesShowed, originDestinationFlowsShowed },
  } = useContext(AppContext);
  const initializedRef = useRef(false);
  const deckRef = useRef<Deck>();
  const tooltipElementRef = useRef<HTMLElement | null>();
  const tooltipContainerRef = useRef<Root>();
  const highlightedFlowTooltip = useRef<Popup>();
  const { t } = useTranslation();
  const { formatNumber } = useUnits();
  const { zoneId } = useH3({
    zones,
    resolution: h3Resolution,
    h3Features,
    deckInitialized: initialized,
    setH3Features,
  });

  useEffect(() => {
    function handleMoveEnd() {
      if (!map) return;

      const { lat: latitude, lng: longitude } = map.getCenter();

      deckRef.current?.setProps({
        viewState: {
          longitude,
          latitude,
          zoom: map.getZoom(),
          bearing: map.getBearing(),
          pitch: map.getPitch(),
        },
      });
    }

    if (initialized) {
      map?.on('move', handleMoveEnd);

      tooltipElementRef.current = document.getElementById(`h3-tooltip`);
      if (!tooltipElementRef.current) return;
      tooltipContainerRef.current = createRoot(tooltipElementRef.current);
    }

    return () => {
      map?.off('moveend', handleMoveEnd);
    };
  }, [initialized]);

  useEffect(() => {
    if (initialized && h3Features) updateLayers(h3Features);

    return () => {
      if (document.getElementsByClassName('deck-tooltip')[0])
        (document.getElementsByClassName('deck-tooltip')[0] as HTMLElement).style.display = 'none';
      setBounds({ min: 0, max: 1 });
      setCurrentRange([0, 1]);
    };
  }, [
    initialized,
    h3Features,
    _externalH3Features,
    selectedH3Indices,
    _flows,
    originDestinationZonesShowed,
    originDestinationFlowsShowed,
  ]);

  function init(canvas: HTMLCanvasElement) {
    if (!map || !canvas || initializedRef.current) return;
    if (deckRef.current) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    const { lat: latitude, lng: longitude } = map.getCenter();

    deckRef.current = new Deck({
      canvas,
      width: '100%',
      height: '100%',
      controller: true,
      initialViewState: {
        longitude,
        latitude,
        zoom: map.getZoom(),
        bearing: map.getBearing(),
        pitch: map.getPitch(),
      },
      onViewStateChange: ({ viewState }) => {
        map.jumpTo({
          center: [viewState.longitude, viewState.latitude],
          zoom: viewState.zoom,
          bearing: viewState.bearing,
          pitch: viewState.pitch,
        });
      },
      layers: [],
      effects: [],
    });

    highlightedFlowTooltip.current = new Popup({
      className: 'map-tooltip flowmap-tooltip',
      closeButton: false,
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function flipTooltip({
    ele,
    x,
    y,
    viewport,
  }: {
    ele: HTMLElement;
    viewport: { height: number; width: number };
    x: number;
    y: number;
  }) {
    const x1 = x > viewport.width - ele.clientWidth - 16 ? x - ele.clientWidth - 16 : x;
    const y1 = y > viewport.height - ele.clientHeight - 16 ? y - ele.clientHeight - 16 : y;

    return `translate(${x1}px, ${y1}px)`;
  }

  function handleHover({ index, ...props }: FlowLayerPickingInfo) {
    highlightedFlowTooltip.current?.remove();

    if (!map || index === -1) return;

    if (props.type === PickingType.FLOW) {
      const origin: TLocation = props.origin;
      const destination: TLocation = props.dest;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [lng, lat]: [number, number] = (props as any).coordinate;
      const count: number = props.object.count;

      highlightedFlowTooltip.current
        ?.setHTML(
          `<div>${origin.properties.name || ''}</div><div>&#x21b3; ${
            destination.properties.name || ''
          }</div><h3>${t('commons.stats.journeys', {
            count,
            countFormatted: formatNumber(count),
          })}<h3>`,
        )
        .setLngLat({ lng, lat })
        .addTo(map);
    }
  }

  function updateLayers(h3Features: GeoJSON.Feature<GeoJSON.Polygon, TH3CellFeatureProps>[]) {
    const externalH3Features = [...(_externalH3Features || [])];

    const cellsMap = [...h3Features, ...externalH3Features].reduce<{
      [key: string]: { from: number; to: number; internal: number; total: number };
    }>(
      (res, { properties: { h3Index } }) => {
        res[h3Index] = { from: 0, to: 0, internal: 0, total: 0 };
        return res;
      },
      {
        [selectedH3CellKey]: { from: 0, to: 0, internal: 0, total: 0 },
      },
    );

    const isExternal = externalH3Features.reduce<{ [key: string]: boolean }>(
      (res, { properties: { h3Index } }) => {
        res[h3Index] = true;
        return res;
      },
      {},
    );
    const hasInternalFlows: { [key: string]: boolean } = {};
    const hasExternalFlows: { [key: string]: boolean } = {};
    const flowsMap: { [key: string]: { [key: string]: number } } = {};

    _flows?.forEach(({ origin: _origin, destination: _destination, count }) => {
      const __origin =
        typeof h3Resolution === 'number' && h3Resolution < 9
          ? cellToParent(`${_origin}`, h3Resolution)
          : _origin;
      const origin = selectedH3Indices.includes(__origin) ? selectedH3CellKey : __origin;
      const __destination =
        typeof h3Resolution === 'number' && h3Resolution < 9
          ? cellToParent(`${_destination}`, h3Resolution)
          : _destination;
      const destination = selectedH3Indices.includes(__destination)
        ? selectedH3CellKey
        : __destination;

      if (origin === destination) {
        if (cellsMap[origin]) {
          hasInternalFlows[origin] = true;

          cellsMap[origin].internal += count;
          cellsMap[origin].total += count;
        }
      } else {
        hasExternalFlows[origin] = true;
        hasExternalFlows[destination] = true;

        if (cellsMap[origin]) {
          cellsMap[origin].from += count;
          cellsMap[origin].total += count;
        } else if (typeof h3Resolution === 'number') {
          const polygon: GeoJSON.Polygon = {
            type: 'Polygon',
            coordinates: [cellToBoundary(origin, true)],
          };
          const [lat, lng] = cellToLatLng(origin);

          externalH3Features.push({
            type: 'Feature',
            geometry: polygon,
            properties: {
              center: { lat, lng },
              resolution: h3Resolution,
              h3Index: origin,
              zoneId: `${zoneId.current}`,
            },
          });

          cellsMap[origin] = { from: count, to: 0, internal: 0, total: count };
          isExternal[origin] = true;
        }

        if (cellsMap[destination]) {
          cellsMap[destination].to += count;
          cellsMap[destination].total += count;
        } else if (typeof h3Resolution === 'number') {
          const polygon: GeoJSON.Polygon = {
            type: 'Polygon',
            coordinates: [cellToBoundary(destination, true)],
          };
          const [lat, lng] = cellToLatLng(destination);

          externalH3Features.push({
            type: 'Feature',
            geometry: polygon,
            properties: {
              center: { lat, lng },
              resolution: h3Resolution,
              h3Index: destination,
              zoneId: destination,
            },
          });

          cellsMap[destination] = { from: count, to: 0, internal: 0, total: count };
          isExternal[destination] = true;
        }
      }

      if (!flowsMap[origin]) flowsMap[origin] = {};
      if (!flowsMap[origin][destination]) flowsMap[origin][destination] = count;
      else flowsMap[origin][destination] += count;
    });

    const flows = Object.keys(flowsMap)
      .flatMap((origin) =>
        Object.keys(flowsMap[origin]).map<TFlow>((destination) => ({
          id: `${origin}-${destination}`,
          origin,
          destination,
          count: flowsMap[origin]?.[destination] || 0,
        })),
      )
      .filter(({ origin, destination }) => {
        if (!cellsMap[origin] || !cellsMap[destination]) return false;
        if (selectedH3Indices.length === 0) return true;
        return origin === selectedH3CellKey || destination === selectedH3CellKey;
      })
      .sort((a, b) => b.count - a.count);

    const maxFlowsCount = Math.max(...Object.values(cellsMap).map(({ total }) => total));

    const locations: TLocation[] = [...h3Features, ...externalH3Features].map(
      ({ geometry, properties: { ...props } }) => {
        const count = cellsMap[props.h3Index]?.total || 0;
        const colorIndex = Math.trunc(count / (maxFlowsCount / colors.length));

        return {
          type: 'Feature',
          geometry,
          properties: {
            ...props,
            name:
              props.name ||
              t('cycling-insights.usage.accidentology.h3_cell_tooltip.title', {
                id: props.zoneId,
              }),
            flows: cellsMap[props.h3Index],
            color: colors[colorIndex === colors.length ? colors.length - 1 : colorIndex],
            isExternal: isExternal[props.h3Index],
          },
        };
      },
    );

    const selectedH3MultiPolygon: GeoJSON.MultiPolygon | null =
      selectedH3Indices.length > 0
        ? {
            type: 'MultiPolygon',
            coordinates: cellsToMultiPolygon(selectedH3Indices, true),
          }
        : null;
    const selectedH3Centroid =
      selectedH3MultiPolygon &&
      centroid(selectedH3MultiPolygon, { properties: { h3Index: selectedH3CellKey } });

    deckRef.current?.setProps({
      layers: [
        originDestinationZonesShowed &&
          new GeoJsonLayer({
            id: 'h3-cells',
            data: {
              type: 'FeatureCollection',
              features: locations.filter(({ properties: { h3Index } }) => {
                if (!hasInternalFlows[h3Index] && !hasExternalFlows[h3Index]) return false;
                return !selectedH3Indices.includes(h3Index);
              }),
            },
            pickable: true,
            stroked: true,
            filled: true,
            getLineWidth: 2,
            lineWidthMinPixels: 2,
            getFillColor: ({
              properties: { color },
            }: GeoJSON.Feature<GeoJSON.Polygon, TH3CellFeatureProps>) =>
              (selectedH3Indices.length === 0
                ? [...(color || [255, 255, 255]), 165]
                : [255, 255, 255, 0]) as [number, number, number, number],
            getLineColor: () => [128, 128, 128, 255] as [number, number, number, number],
          }),
        originDestinationZonesShowed &&
          selectedH3Indices.length > 0 &&
          new GeoJsonLayer({
            id: 'selected-h3-cells',
            data: {
              type: 'FeatureCollection',
              features: locations
                .filter(({ properties: { h3Index } }) => {
                  return selectedH3Indices.includes(h3Index);
                })
                .map((feature) => ({
                  ...feature,
                  properties: {
                    ...feature.properties,
                    flows: cellsMap[selectedH3CellKey],
                    name:
                      selectedH3Indices.length > 1 ? 'Zones sélectionnées' : 'Zone sélectionnée',
                  },
                })),
            },
            pickable: true,
            stroked: false,
            filled: true,
            getLineWidth: 2,
            lineWidthMinPixels: 2,
            getFillColor: () => [62, 123, 223, 165] as [number, number, number, number],
          }),
        originDestinationZonesShowed &&
          selectedH3MultiPolygon &&
          selectedH3Centroid &&
          new GeoJsonLayer({
            id: 'selected-h3-cells-border',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: selectedH3MultiPolygon,
                  properties: {},
                },
              ],
            },
            pickable: false,
            stroked: true,
            filled: false,
            getLineWidth: 2,
            lineWidthMinPixels: 2,
            getLineColor: () => [62, 123, 223, 255] as [number, number, number, number],
          }),
        originDestinationFlowsShowed &&
          (new FlowMapLayer({
            id: 'origin-destination-flows-layer',
            colors: {
              locationAreas: {
                outline: '#3e7bdf',
                normal: '#3e7bdf',
                selected: '#3e7bdf',
                highlighted: '#3e7bdf',
                connected: '#3e7bdf',
              },
            },
            locations: {
              type: 'FeatureCollection',
              features: [
                ...locations
                  .filter(({ properties: { h3Index } }) => hasExternalFlows[h3Index])
                  .map<GeoJSON.Feature<GeoJSON.Point, TH3CellFeatureProps>>((feature) => {
                    const { lat, lng } = feature.properties.center;

                    return { ...feature, geometry: { type: 'Point', coordinates: [lng, lat] } };
                  }),
                ...(selectedH3Centroid ? [selectedH3Centroid] : []),
              ],
            },
            flows,
            autoHighlight: false,
            pickable: true,
            showTotals: false,
            maxLocationCircleSize: 0,
            getFlowColor: ({ origin, destination }) => {
              if (isExternal[origin] || isExternal[destination]) return '#FFD12F';
              return '#3E7BDF';
            },
            getFlowMagnitude: ({ count }: TFlow) => count,
            getFlowOriginId: ({ origin }: TFlow) => origin,
            getFlowDestId: ({ destination }: TFlow) => destination,
            getLocationId: ({
              properties: { h3Index },
            }: GeoJSON.Feature<GeoJSON.Point, TH3CellFeatureProps>) => h3Index,
            getLocationCentroid: ({
              geometry: {
                coordinates: [lng, lat],
              },
            }: GeoJSON.Feature<GeoJSON.Point, TH3CellFeatureProps>) => [lng, lat],
            onHover: handleHover,
            // onClick: (event) => handleClick(event, cyclabilityZones),
          }) as unknown as Layer<any>), // eslint-disable-line @typescript-eslint/no-explicit-any
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick: ({
        layer,
        object,
      }: {
        layer: { id: string };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        object?: any;
      }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (layer?.id === 'h3-cells') {
          if (!object) return;

          const {
            properties: { h3Index, isExternal },
          } = object;

          if (isExternal || h3Resolution === 'cyclabilityZones') return;

          const index = selectedH3Indices.findIndex((index) => index === h3Index);
          if (index === -1) {
            selectH3Indices([...selectedH3Indices, h3Index]);
          }
        } else if (layer?.id === 'selected-h3-cells') {
          if (!object) return;

          const {
            properties: { h3Index },
          } = object;

          const index = selectedH3Indices.findIndex((index) => index === h3Index);
          if (index > -1) {
            const indices = [...selectedH3Indices];
            indices.splice(index, 1);
            selectH3Indices(indices);
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getTooltip: ({
        layer,
        object,
        x,
        y,
        viewport,
      }: {
        layer: { id: string };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        object?: any;
        viewport: { height: number; width: number };
        x: number;
        y: number;
      }) => {
        if (!tooltipElementRef.current || !tooltipContainerRef.current || !object) return undefined;

        if (layer.id === 'h3-cells' || layer.id === 'selected-h3-cells') {
          tooltipContainerRef.current.render(<H3CellTooltip object={object} />);

          return {
            style: { transform: flipTooltip({ ele: tooltipElementRef.current, x, y, viewport }) },
            html: tooltipElementRef.current.innerHTML,
          };
        }

        return undefined;
      },
    });

    setBounds({ min: 0, max: maxFlowsCount });
    setCurrentRange([0, maxFlowsCount]);
  }

  function clear() {
    highlightedFlowTooltip.current = undefined;
    deckRef.current = undefined;
    initializedRef.current = false;
    setInitialized(false);
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, clear, destroy };
}

export default useOriginDestinationFlows;
