import { GeoveloInlineIcon, useFileSaver } from '@geovelo-frontends/commons';
import { Box, DialogProps, Skeleton, Typography, useTheme } from '@mui/material';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';
import DepartureIcon from '../../../../components/icons/departure';
import NorthIcon from '../../../../components/icons/north';
import Map from '../../../../components/map';
import IsochronesLegendControl from '../../../../components/map/isochrones-legend-control';
import useIsochrones from '../../../../hooks/map/isochrones';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';

import { LngLatBounds, Map as MaplibreMap } from '!maplibre-gl';

type TProps = Omit<DialogProps, 'onClose'> & { onClose: () => void; eBikeEnabled: boolean };

function PreviewDialog({ eBikeEnabled, onClose, ...props }: TProps): JSX.Element {
  const [map, setMap] = useState<MaplibreMap>();
  const [bounds, setBounds] = useState<LngLatBounds>();
  const printRef = useRef<HTMLDivElement | null>(null);
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const [downloading, setDownloading] = useState(false);
  const {
    map: { baseLayer },
  } = useContext(AppContext);
  const { downloadBlob } = useFileSaver();
  const theme = useTheme();
  const {
    isochrones: { data, departures },
  } = context;
  const {
    init: initLayers,
    setDepartures: setDeparturesOnMap,
    update: updateLayers,
    destroy: destroyLayers,
  } = useIsochrones(map, theme);

  useEffect(() => {
    return () => destroyLayers();
  }, [props.open]);

  useEffect(() => {
    if (data) {
      const sortedIsochrones = [...data].sort((a, b) => a.duration - b.duration);
      const {
        bounds: { north, east, south, west },
      } = sortedIsochrones[sortedIsochrones.length - 1];

      setBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }));
    }
  }, [data]);

  useEffect(() => {
    if (map) {
      initLayers();
      setDeparturesOnMap(departures, false);
      updateLayers(data, false);
    }
  }, [map]);

  async function handleDownload() {
    setDownloading(true);

    if (printRef.current) {
      const canvas = await html2canvas(printRef.current, {
        scale: 1,
        width: printRef.current.clientWidth,
        height: printRef.current.clientHeight,
        useCORS: true,
      });

      const blob = await new Promise<Blob>((resolve, reject) => {
        try {
          canvas.toBlob(
            (blob) => {
              if (!blob) throw new Error('no blob');
              else resolve(blob);
            },
            'image/png',
            1.0,
          );
        } catch (err) {
          reject(err);
        }
      });

      downloadBlob(`isochrones.png`, blob);
    }

    setDownloading(false);
  }

  return (
    <Dialog
      fullWidth
      confirmTitle={<Trans i18nKey="commons.actions.download" />}
      dialogTitle="isochrones-download-dialog"
      loading={downloading}
      maxWidth="md"
      onCancel={onClose}
      onConfirm={handleDownload}
      title={<Trans i18nKey="cycling-insights.facilities.isochrones.export_dialog.title" />}
      {...props}
    >
      <Box alignSelf="center" maxWidth="100%" overflow="auto">
        <Box border="1px solid #ddd" width="fit-content">
          <Box ref={printRef} sx={{ zoom: '15%' }}>
            <Box width={3178}>
              <Box display="flex" flexDirection="column" gap={10} paddingX={3} paddingY={10}>
                <Box
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography fontWeight={600} variant="h1">
                    <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.isochrones" />
                  </Typography>
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <GeoveloInlineIcon sx={{ height: 100, width: 'auto' }} />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant="h3">
                    Estimation des temps de trajet à vélo depuis :
                  </Typography>
                  <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                    <DepartureIcon sx={{ height: 48, width: 48 }} />
                    <Typography lineHeight={1} variant="h4">
                      {departures[0]?.address}
                    </Typography>
                  </Box>
                </Box>
                {props.open ? (
                  <Box
                    disableInteractions
                    disableZoomControl
                    hasScaleControl
                    baseLayer={baseLayer}
                    bounds={bounds}
                    component={Map}
                    fitBoundsOptions={{ padding: 50 }}
                    height={3130}
                    mapId="isochrones-map"
                    onInit={setMap}
                    width={3130}
                  >
                    <StyledNorthIcon />
                  </Box>
                ) : (
                  <Skeleton height={3130} variant="rectangular" width={3130} />
                )}
                {data && (
                  <>
                    <Box
                      alignItems="flex-start"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="column" gap={3}>
                        <Typography variant="h3">Temps en minutes</Typography>
                        <IsochronesLegendControl horizontal={true} isochrones={data} size="large" />
                      </Box>
                      <Box display="flex" flexDirection="column" gap={3}>
                        <Typography variant="h3">
                          Cette accessibilité est calculée avec les hypothèses suivantes :
                        </Typography>
                        <Box paddingLeft={10}>
                          <Typography component="ul" variant="h4">
                            <li>{eBikeEnabled ? 'vélo électrique' : 'vélo classique'}</li>
                            <li>{eBikeEnabled ? '19 km/h' : '15 km/h'}</li>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Typography alignSelf="flex-end" marginTop={2} variant="h5">
                  Sources: Les Contributeurs OpenStreetMap ; geovelo -{' '}
                  {moment().format('MMMM YYYY')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

const StyledNorthIcon = styled(NorthIcon)`
  z-index: 2;
  position: absolute;
  left: 8px;
  bottom: 8px;
  && {
    height: 36px;
    width: 36px;
  }
`;

export default PreviewDialog;
