import { CyclabilityZone } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import { IBicycleObservatoryPageContext } from '../../context';

const roadsTypesChartId = 'roads-types-chart';

function OriginDestinationChart({
  oldOriginDestination: {
    externalFlowsCount,
    flows,
    internalFlowsCount,
    otherInternalFlowsCount,
    selectedZoneId,
    setExternalFlowsCount,
    setInternalFlowsCount,
    setOtherInternalFlowsCount,
    zones,
  },
}: IBicycleObservatoryPageContext): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();
  const [selectedZone, selectZone] = useState<CyclabilityZone>();

  useEffect(() => {
    setInternalFlowsCount(0);
    setExternalFlowsCount(0);
    setOtherInternalFlowsCount(0);

    let internalFlows = 0;
    let externalFlows = 0;
    let otherInternalFlows = 0;
    let zone;
    if (zones && flows) {
      zone = zones.find(({ id }) => id === selectedZoneId);
      selectZone(zone);
      const cyclabilityZonesMap = zones.reduce<{ [key: number]: CyclabilityZone }>(
        (res, cyclabilityZone) => {
          res[cyclabilityZone.id] = cyclabilityZone;
          return res;
        },
        {},
      );

      flows
        .filter(({ origin, destination }) => {
          if (selectedZoneId) return origin === selectedZoneId || destination === selectedZoneId;
          else return true;
        })
        .forEach(({ origin, destination, count }) => {
          const originZone = cyclabilityZonesMap[origin];
          const destinationZone = cyclabilityZonesMap[destination];

          if (!originZone && !destinationZone) return;
          if (!originZone || !destinationZone) externalFlows += count;
          else {
            if (selectedZoneId) {
              if (selectedZoneId === origin && selectedZoneId === destination)
                internalFlows += count;
              else otherInternalFlows += count;
            } else internalFlows += count;
          }
        });
    }
    setInternalFlowsCount(internalFlows);
    setExternalFlowsCount(externalFlows);
    setOtherInternalFlowsCount(otherInternalFlows);

    const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
    if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
        type: 'doughnut',
        data: {
          labels: selectedZoneId
            ? [
                t('cycling-insights.usage.origin_destination.chart.zone_internal_flows', {
                  zone: zone?.name,
                }),
                t('cycling-insights.usage.origin_destination.chart.zone_other_flows', {
                  zone: zone?.name,
                  partner: currentPartner?.title,
                }),
                t('cycling-insights.usage.origin_destination.chart.zone_external_flows', {
                  zone: zone?.name,
                  partner: currentPartner?.title,
                }),
              ]
            : [
                t('cycling-insights.usage.origin_destination.chart.internal_flows', {
                  partner: currentPartner?.title,
                }),
                t('cycling-insights.usage.origin_destination.chart.external_flows', {
                  partner: currentPartner?.title,
                }),
              ],
          datasets: flows
            ? [
                {
                  data: selectedZoneId
                    ? [internalFlows, otherInternalFlows, externalFlows]
                    : [internalFlows, externalFlows],
                  backgroundColor: selectedZoneId
                    ? ['#3E7BDF', '#9BC2FF', '#FFD12F']
                    : ['#3E7BDF', '#FFD12F'],
                },
              ]
            : [
                {
                  data: [70, 30],
                  backgroundColor: ['#E3E7EE', '#F6F7FB'],
                },
              ],
        },
        options: {
          cutout: '80%',
          events: flows ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : [],
          responsive: true,
          maintainAspectRatio: false,
          plugins: flows
            ? {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: ({ parsed, label }) =>
                      ` ${label}: ${
                        Math.round((parsed / (internalFlows + externalFlows)) * 1000) / 10
                      } %`,
                  },
                },
                title: {
                  display: false,
                },
              }
            : {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
        },
      });
    }
  }, [flows, selectedZoneId]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize="1.125rem" fontWeight={700} marginBottom={3}>
        <Trans i18nKey="cycling-insights.usage.origin_destination.chart.title" />
        {selectedZoneId && ` - ${zones?.find(({ id }) => id === selectedZoneId)?.name}`}
      </Typography>
      <Box display="flex" flexDirection="row" gap={4} justifyContent="space-between">
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          minWidth={200}
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            left="36px"
            position="absolute"
            textAlign="center"
            width="128px"
          >
            {flows ? (
              <Typography fontWeight={700} variant="body1">
                {externalFlowsCount + internalFlowsCount + otherInternalFlowsCount}
              </Typography>
            ) : (
              <Skeleton variant="text" width={96} />
            )}
            <Typography variant="body2">
              {t('commons.stats_types.journeys').toLocaleLowerCase()}
            </Typography>
          </Box>
          <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
          {flows ? (
            <>
              <Box display="flex" flexDirection="column">
                <Box
                  bgcolor="#3E7BDF"
                  borderRadius="5px"
                  height="10px"
                  marginBottom="2px"
                  minWidth="10px"
                  width="10px"
                />
                <Typography variant="caption">
                  {selectedZoneId ? (
                    <Trans
                      i18nKey="cycling-insights.usage.origin_destination.chart.zone_internal_flows"
                      values={{ zone: selectedZone?.name }}
                    />
                  ) : (
                    <Trans
                      i18nKey="cycling-insights.usage.origin_destination.chart.internal_flows"
                      values={{ zone: selectedZone?.name }}
                    />
                  )}
                </Typography>
                <Typography variant="caption">
                  {internalFlowsCount || otherInternalFlowsCount || externalFlowsCount
                    ? Math.round(
                        (internalFlowsCount /
                          (internalFlowsCount + otherInternalFlowsCount + externalFlowsCount)) *
                          1000,
                      ) / 10
                    : 0}
                  %
                </Typography>
              </Box>
              {selectedZoneId && (
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#9BC2FF"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans
                      i18nKey="cycling-insights.usage.origin_destination.chart.zone_other_flows"
                      values={{ zone: selectedZone?.name, partner: currentPartner?.title }}
                    />
                  </Typography>
                  <Typography variant="caption">
                    {internalFlowsCount || otherInternalFlowsCount || externalFlowsCount
                      ? Math.round(
                          (otherInternalFlowsCount /
                            (internalFlowsCount + otherInternalFlowsCount + externalFlowsCount)) *
                            1000,
                        ) / 10
                      : 0}
                    %
                  </Typography>
                </Box>
              )}
              <Box display="flex" flexDirection="column">
                <Box
                  bgcolor="#FFD12F"
                  borderRadius="5px"
                  height="10px"
                  marginBottom="2px"
                  minWidth="10px"
                  width="10px"
                />
                <Typography variant="caption">
                  {selectedZoneId ? (
                    <Trans
                      i18nKey="cycling-insights.usage.origin_destination.chart.zone_external_flows"
                      values={{ zone: selectedZone?.name, partner: currentPartner?.title }}
                    />
                  ) : (
                    <Trans
                      i18nKey="cycling-insights.usage.origin_destination.chart.external_flows"
                      values={{ partner: currentPartner?.title }}
                    />
                  )}
                </Typography>
                <Typography variant="caption">
                  {internalFlowsCount || otherInternalFlowsCount || externalFlowsCount
                    ? Math.round(
                        (externalFlowsCount /
                          (internalFlowsCount + otherInternalFlowsCount + externalFlowsCount)) *
                          1000,
                      ) / 10
                    : 0}
                  %
                </Typography>
              </Box>
            </>
          ) : (
            [0, 1, 2].map((key) => <Skeleton key={key} variant="text" width={128} />)
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default OriginDestinationChart;
