import {
  GeocoderService,
  IPoint,
  IsochroneService,
  Place,
  TCyclingProfile,
  useAmplitudeTracker,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Step, StepContent, StepLabel, Stepper, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../../app/context';
import Button from '../../../../../components/button';
import IsochronesLegendControl from '../../../../../components/map/isochrones-legend-control';
import TabIntroduction from '../../../../../components/tab-introduction';
import useIsochrones from '../../../../../hooks/map/isochrones';
import { TOutletContext } from '../../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../../context';

import IsochronesDurationsForm, { durations as durationKeys } from './durations';
import IsochronesPointsForm from './points';
import IsochronesSettingsForm from './settings';

function IsochronesForm({
  loading,
  isochrones: {
    eBikeEnabled,
    pointType,
    departures,
    durations,
    data,
    setPointType,
    setDepartures,
    setDurations,
    setData,
    toggleEBike,
  },
  setLoading,
}: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const [durationErrors, setDurationErrors] = useState<{ [key: number]: boolean }>({});
  const [profile, setProfile] = useState<TCyclingProfile>('safe');
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    init: initLayers,
    setDepartures: setDeparturesOnMap,
    update: updateLayers,
    destroy: destroyLayers,
  } = useIsochrones(currentMap, theme, handleMapClick);
  const isochronesRef = useRef(data);
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    return () => {
      cancelPromises();
      setData(undefined);
      setPointType('single');
      setDepartures([]);
      setDurations({ 0: 10, 1: 20, 2: 30 });
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const errors: { [key: number]: boolean } = {};

    const correctValues: number[] = [];
    durationKeys.forEach((index) => {
      const value = durations[index];
      if (value === undefined || value === '') return;

      if (typeof value !== 'number' || value < 1 || correctValues.indexOf(value) > -1)
        errors[index] = true;
      else {
        correctValues.push(value);
      }
    });

    if (correctValues.length === 0) errors[0] = true;

    setDurationErrors(errors);
  }, [durations]);

  useEffect(() => {
    setDeparturesOnMap(departures);

    let active = true;

    if (pointType === 'single') {
      const [place] = departures;
      if (!place || place.addressDetail) return;

      GeocoderService.reverseGeocode(undefined, place.point, (geocodedPlace) => {
        if (active) setDepartures([geocodedPlace]);
      });
    }

    return () => {
      active = false;
    };
  }, [departures]);

  useEffect(() => {
    isochronesRef.current = data;
    updateLayers(data);
  }, [data]);

  useEffect(() => {
    if (currentMap) initLayers();

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  function handleMapClick({ lat, lng }: IPoint) {
    if (pointType === 'single' && !isochronesRef.current) {
      setDepartures([new Place(new Date().getTime(), { type: 'Point', coordinates: [lng, lat] })]);
    }
  }

  async function handleSubmit() {
    cancelPromises();
    setData(undefined);

    let cancelled = false;
    setLoading(true);

    try {
      const locations = departures.map(
        ({
          address,
          point: {
            coordinates: [longitude, latitude],
          },
        }) => ({
          title: address,
          latitude,
          longitude,
        }),
      );

      let _durations: number[] = [];
      Object.values(durations).forEach((value) => {
        if (typeof value === 'number' && value > 0) _durations.push(value * 60);
      });
      _durations = [...new Set(_durations)];

      trackEvent('Button Clicked', {
        pathname: `${window.location.host}${window.location.pathname}`,
        partner_id: currentPartner?.id,
        partner_code: currentPartner?.code,
        cta: 'Compute Isochrones Button',
        duration_max: `${Math.max(..._durations)}`,
      });

      const isochrones = await cancellablePromise(
        IsochroneService.computeIsochrones({
          durations: _durations,
          locations,
          profile,
          eBikeEnabled,
        }),
      );

      setData(isochrones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.isochrones.server_error'), {
          variant: 'error',
        });
      } else cancelled = true;
    }

    if (!cancelled) setLoading(false);
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="calc(100% - 32px)">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.isochrones" />
      <Box padding={2}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>
              <Trans i18nKey="cycling-insights.facilities.isochrones.actions.choose_points" />
            </StepLabel>
            <StepContent>
              <IsochronesPointsForm
                departures={departures}
                onDeparturesChange={setDepartures}
                onTypeChange={setPointType}
                type={pointType}
              />
              <StyledStepActions>
                <Button
                  color="primary"
                  disabled={departures.length === 0}
                  onClick={() => setActiveStep(activeStep + 1)}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.next" />
                </Button>
              </StyledStepActions>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Trans i18nKey="cycling-insights.facilities.isochrones.actions.choose_durations" />
            </StepLabel>
            <StepContent>
              <IsochronesDurationsForm
                errors={durationErrors}
                onChange={setDurations}
                values={durations}
              />
              <StyledStepActions>
                <Button onClick={() => setActiveStep(activeStep - 1)} variant="outlined">
                  <Trans i18nKey="commons.actions.prev" />
                </Button>
                <Button
                  color="primary"
                  disabled={Object.keys(durationErrors).length > 0}
                  onClick={() => setActiveStep(activeStep + 1)}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.next" />
                </Button>
              </StyledStepActions>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Trans i18nKey="cycling-insights.facilities.isochrones.actions.choose_search_settings" />
            </StepLabel>
            <StepContent>
              <IsochronesSettingsForm
                disabled={loading}
                eBikeEnabled={eBikeEnabled}
                onEBikeToggle={toggleEBike}
                onProfileChange={setProfile}
                profile={profile}
              />
              <StyledStepActions>
                <Button
                  disabled={loading}
                  onClick={() => {
                    setData(undefined);
                    setActiveStep(activeStep - 1);
                  }}
                  variant="outlined"
                >
                  <Trans i18nKey="commons.actions.prev" />
                </Button>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.compute" />
                </Button>
              </StyledStepActions>
            </StepContent>
          </Step>
        </Stepper>
        <Box flexGrow={1} />
        {data && <IsochronesLegendControl isochrones={data} />}
      </Box>
    </Box>
  );
}

const StyledStepActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button {
    margin-left: 8px;
  }
`;

export default IsochronesForm;
