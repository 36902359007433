import { CyclabilityZone } from '@geovelo-frontends/commons';
import { FilterList } from '@mui/icons-material';
import { Box, Button, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Trans } from 'react-i18next';

export type TType = 'internal' | 'external';
const types: TType[] = ['internal', 'external'];
export type TReason = 'work' | 'leisure';
const reasons: TReason[] = ['work', 'leisure'];

function OriginDestinationFilters({
  selectedOrigins,
  selectedDestinations,
  selectedReasons,
  selectedTypes,
  selectOrigins,
  selectDestinations,
  selectReasons,
  selectTypes,
  zones,
  externalZones,
}: {
  selectedOrigins?: CyclabilityZone[];
  selectedDestinations?: CyclabilityZone[];
  selectedReasons: TReason[];
  selectedTypes: TType[];
  selectOrigins: (origins: CyclabilityZone[]) => void;
  selectDestinations: (destinations: CyclabilityZone[]) => void;
  selectReasons: (reasons: TReason[]) => void;
  selectTypes: (types: TType[]) => void;
  zones?: CyclabilityZone[];
  externalZones?: CyclabilityZone[];
}): JSX.Element {
  const [reasonsMenuAnchorEl, setReasonsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [typesMenuAnchorEl, setTypesMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [originsMenuAnchorEl, setOriginsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [destinationsMenuAnchorEl, setDestinationsMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  return (
    <Box alignItems="center" display="flex" gap={2} justifyContent="flex-end">
      <Button
        color="inherit"
        endIcon={<FilterList />}
        onClick={({ currentTarget }) => setReasonsMenuAnchorEl(currentTarget)}
        sx={{ border: '1px solid #C7CEDC', textTransform: 'initial' }}
        variant="outlined"
      >
        <Trans i18nKey="commons.stats.reason_label" />
      </Button>
      <Menu
        keepMounted
        anchorEl={reasonsMenuAnchorEl}
        id="reasons-menu"
        MenuListProps={{ style: { width: 250 } }}
        onClose={() => setReasonsMenuAnchorEl(null)}
        open={Boolean(reasonsMenuAnchorEl)}
      >
        {reasons.map((reason) => {
          const checked = selectedReasons.indexOf(reason) > -1;

          return (
            <MenuItem
              dense
              key={reason}
              onClick={() =>
                checked
                  ? selectReasons(selectedReasons.filter((key) => key !== reason))
                  : selectReasons([...selectedReasons, reason])
              }
              value={reason}
            >
              <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
              <ListItemText
                primary={
                  <Trans
                    i18nKey={`cycling-insights.usage.origin_destination.ranking.trips_types.${reason}`}
                  />
                }
              />
            </MenuItem>
          );
        })}
      </Menu>
      <Button
        color="inherit"
        endIcon={<FilterList />}
        onClick={({ currentTarget }) => setTypesMenuAnchorEl(currentTarget)}
        sx={{ border: '1px solid #C7CEDC', textTransform: 'initial' }}
        variant="outlined"
      >
        <Trans i18nKey="commons.stats.type_label" />
      </Button>
      <Menu
        keepMounted
        anchorEl={typesMenuAnchorEl}
        id="types-menu"
        MenuListProps={{ style: { width: 250 } }}
        onClose={() => setTypesMenuAnchorEl(null)}
        open={Boolean(typesMenuAnchorEl)}
      >
        {types.map((type) => {
          const checked = selectedTypes.indexOf(type) > -1;

          return (
            <MenuItem
              dense
              key={type}
              onClick={() =>
                checked
                  ? selectTypes(selectedTypes.filter((key) => key !== type))
                  : selectTypes([...selectedTypes, type])
              }
              value={type}
            >
              <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
              <ListItemText
                primary={<Trans i18nKey={`cycling-insights.usage.origin_destination.${type}`} />}
              />
            </MenuItem>
          );
        })}
      </Menu>
      <Button
        color="inherit"
        disabled={!zones || !externalZones || !selectedOrigins}
        endIcon={<FilterList />}
        onClick={({ currentTarget }) => setOriginsMenuAnchorEl(currentTarget)}
        sx={{ border: '1px solid #C7CEDC', textTransform: 'initial' }}
        variant="outlined"
      >
        <Trans i18nKey="cycling-insights.usage.origin_destination.origin" />
      </Button>
      <Menu
        keepMounted
        anchorEl={originsMenuAnchorEl}
        id="origins-menu"
        MenuListProps={{ style: { width: 250 } }}
        onClose={() => setOriginsMenuAnchorEl(null)}
        open={Boolean(originsMenuAnchorEl)}
      >
        <MenuItem
          dense
          onClick={() =>
            zones &&
            externalZones &&
            selectedOrigins &&
            selectOrigins(
              selectedOrigins.length === zones.length + externalZones.length
                ? []
                : [...zones, ...externalZones],
            )
          }
        >
          <Checkbox
            checked={
              (selectedOrigins &&
                zones &&
                externalZones &&
                selectedOrigins.length === zones.length + externalZones.length) ||
              false
            }
            indeterminate={
              selectedOrigins &&
              zones &&
              externalZones &&
              selectedOrigins.length > 0 &&
              selectedOrigins.length !== zones.length + externalZones.length
            }
            style={{ padding: 4, marginRight: 8 }}
          />
          <ListItemText primary={<Trans i18nKey="commons.all" />} />
        </MenuItem>
        {!!zones &&
          !!externalZones &&
          !!selectedOrigins &&
          [...zones, ...externalZones]
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((zone) => {
              const checked = selectedOrigins.indexOf(zone) > -1;

              return (
                <MenuItem
                  dense
                  key={zone.id}
                  onClick={() =>
                    checked
                      ? selectOrigins(selectedOrigins.filter((key) => key !== zone))
                      : selectOrigins([...selectedOrigins, zone])
                  }
                  value={zone.id}
                >
                  <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                  <ListItemText primary={zone.name} />
                </MenuItem>
              );
            })}
      </Menu>
      <Button
        color="inherit"
        disabled={!zones || !externalZones || !selectedDestinations}
        endIcon={<FilterList />}
        onClick={({ currentTarget }) => setDestinationsMenuAnchorEl(currentTarget)}
        sx={{ border: '1px solid #C7CEDC', textTransform: 'initial' }}
        variant="outlined"
      >
        <Trans i18nKey="cycling-insights.usage.origin_destination.destination" />
      </Button>
      <Menu
        keepMounted
        anchorEl={destinationsMenuAnchorEl}
        id="destinations-menu"
        MenuListProps={{ style: { width: 250 } }}
        onClose={() => setDestinationsMenuAnchorEl(null)}
        open={Boolean(destinationsMenuAnchorEl)}
      >
        <MenuItem
          dense
          onClick={() =>
            zones &&
            externalZones &&
            selectedDestinations &&
            selectDestinations(
              selectedDestinations.length === zones.length + externalZones.length
                ? []
                : [...zones, ...externalZones],
            )
          }
        >
          <Checkbox
            checked={
              (selectedDestinations &&
                zones &&
                externalZones &&
                selectedDestinations.length === zones.length + externalZones.length) ||
              false
            }
            indeterminate={
              selectedDestinations &&
              zones &&
              externalZones &&
              selectedDestinations.length > 0 &&
              selectedDestinations.length !== zones.length + externalZones.length
            }
            style={{ padding: 4, marginRight: 8 }}
          />
          <ListItemText primary={<Trans i18nKey="commons.all" />} />
        </MenuItem>
        {!!zones &&
          !!externalZones &&
          !!selectedDestinations &&
          [...zones, ...externalZones]
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((zone) => {
              const checked = selectedDestinations.indexOf(zone) > -1;

              return (
                <MenuItem
                  dense
                  key={zone.id}
                  onClick={() =>
                    checked
                      ? selectDestinations(selectedDestinations.filter((key) => key !== zone))
                      : selectDestinations([...selectedDestinations, zone])
                  }
                  value={zone.id}
                >
                  <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                  <ListItemText primary={zone.name} />
                </MenuItem>
              );
            })}
      </Menu>
    </Box>
  );
}

export default OriginDestinationFilters;
