import {
  CyclabilityZone,
  CyclabilityZoneService,
  Flow,
  OriginDestinationService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import OriginDestinationFilters, {
  TReason,
  TType,
} from '../../../../components/form/origin-destination-filters';
import PeriodForm from '../../../../components/form/period';
import useOriginDestinationFlows from '../../../../hooks/map/old-origin-destination-flows';
import { TOutletContext } from '../../../../layouts/page/container';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';
import { IBicycleObservatoryPageContext } from '../../context';
import { periodProps } from '../old-origin-destination/form';

import OriginDestinationTable from './table';

function OriginDestinationListForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    header: { setPrevButtonClick },
    period,
    oldOriginDestination: {
      canvasRef,
      currentRange,
      externalZones,
      flows,
      setZones,
      setFlows,
      setBounds,
      setCurrentRange,
      setExternalZones,
      zones,
    },
    setLoading,
  } = context;
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
    user: { current: currentUser },
  } = useContext(AppContext);
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    clear: clearLayers,
  } = useOriginDestinationFlows(currentMap, {});
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const [selectedOrigins, selectOrigins] = useState<CyclabilityZone[]>();
  const [selectedDestinations, selectDestinations] = useState<CyclabilityZone[]>();
  const [selectedTypes, selectTypes] = useState<TType[]>(['internal', 'external']);
  const [selectedReasons, selectReasons] = useState<TReason[]>(['work', 'leisure']);
  const [filteredFlows, filterFlows] = useState<Flow[]>();

  useEffect(() => {
    getZones();
    setPrevButtonClick(() => () => navigate('../origin-destination'));

    return () => {
      cancelPromises();
      setFlows(undefined);
      setPrevButtonClick(undefined);
    };
  }, []);

  useEffect(() => {
    if (currentMap && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [currentMap, canvasRef.current]);

  useEffect(() => {
    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (flows) {
      const filteredData = getFilteredFlows(flows, externalZones);
      setCurrentRange([0, filteredData.length]);
      filterFlows(filteredData);
    }
  }, [flows, externalZones, selectedDestinations, selectedOrigins, selectedTypes]);

  useEffect(() => {
    if (layersInitialized) {
      updateLayers(zones, externalZones, filteredFlows, currentRange, null);
    }
  }, [layersInitialized, zones, externalZones, filteredFlows, currentRange]);

  useEffect(() => {
    getData();
  }, [period.values, zones, selectedReasons]);

  async function getData() {
    cancelPromises();
    setFlows(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);

    setLoading(true);
    filterFlows(undefined);

    if (!currentPartner || !zones || selectedReasons.length === 0) {
      setLoading(false);
      filterFlows([]);
      return;
    }

    const {
      values: { current: currentPeriod, timePeriod, dayPeriod },
    } = period;

    if (!timePeriod || !dayPeriod) return;

    let cancelled = false;

    try {
      const zoneIds = zones.map(({ id }) => id);

      let flows;
      if (selectedReasons.includes('leisure') && !selectedReasons.includes('work')) {
        const [weekFlows, weekEndOutgoingFlows] = await cancellablePromise(
          Promise.all([
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps.leisure[0]),
            }),
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps.leisure[1]),
            }),
          ]),
        );
        weekEndOutgoingFlows.forEach((weekEndFlow) => {
          const flow = weekFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else weekFlows.push(weekEndFlow);
        });
        flows = [...weekFlows];
      } else {
        flows = await cancellablePromise(
          OriginDestinationService.getFlows({
            period: currentPeriod.toIPeriod(),
            departureCyclabilityZoneIds: zoneIds,
            arrivalCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(
              selectedReasons.includes('leisure') ? periodProps.all[0] : periodProps.work[0],
            ),
          }),
        );
      }

      const externalZonesIds = flows.reduce<number[]>((res, { origin, destination }) => {
        if (!zoneIds.includes(origin) && !res.includes(origin)) res.push(origin);
        if (!zoneIds.includes(destination) && !res.includes(destination)) res.push(destination);
        return res;
      }, []);

      let newExternalZones: CyclabilityZone[] = [];
      if (currentUser?.isGeovelo) {
        const result = await cancellablePromise(
          CyclabilityZoneService.getZones({
            administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
            considerLivingStreets: true,
            ids: externalZonesIds,
            query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
          }),
        );
        newExternalZones = result.zones;
        setExternalZones(newExternalZones);
        selectDestinations([...zones, ...newExternalZones]);
        selectOrigins([...zones, ...newExternalZones]);
      } else {
        setExternalZones([]);
        selectDestinations([...zones]);
        selectOrigins([...zones]);
      }

      setBounds({ min: 0, max: flows.length });
      setFlows(flows.filter(({ count }) => count > 1));

      const filteredData = getFilteredFlows(flows, newExternalZones);
      setCurrentRange([0, filteredData.length]);
      filterFlows(filteredData);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setBounds({ min: 0, max: 1 });
        setCurrentRange([0, 1]);
        setFlows([]);
        filterFlows([]);
      } else cancelled = true;
    }

    if (!cancelled) setLoading(false);
  }

  async function getZones() {
    if (!currentPartner) return;
    setLoading(true);
    try {
      const { zones } = await CyclabilityZoneService.getZones({
        administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
        partnerCode: currentPartner.code,
        considerLivingStreets: true,
        rowsPerPage: 100,
        query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
      });
      setZones(zones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  function getFilteredFlows(flows: Flow[], externalZones?: CyclabilityZone[]): Flow[] {
    if (!zones) return [];
    return flows.filter(({ origin, destination }) => {
      if (!selectedOrigins?.find(({ id }) => id === origin)) return false;
      if (!selectedDestinations?.find(({ id }) => id === destination)) return false;
      if (
        !selectedTypes.includes('internal') &&
        zones.find(({ id }) => id === origin) &&
        zones.find(({ id }) => id === destination)
      )
        return false;
      if (
        !selectedTypes.includes('external') &&
        (externalZones?.find(({ id }) => id === origin) ||
          externalZones?.find(({ id }) => id === destination))
      )
        return false;
      return true;
    });
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={5} minHeight="100%">
        <Box display="flex" flexDirection="column" gap={3}>
          <PeriodForm disableComparison disablePadding {...period} />
          <OriginDestinationFilters
            externalZones={externalZones}
            selectDestinations={selectDestinations}
            selectedDestinations={selectedDestinations}
            selectedOrigins={selectedOrigins}
            selectedReasons={selectedReasons}
            selectedTypes={selectedTypes}
            selectOrigins={selectOrigins}
            selectReasons={selectReasons}
            selectTypes={selectTypes}
            zones={zones}
          />
          <Box flexGrow={1} marginX={-3} sx={{ overflowY: 'auto' }}>
            <OriginDestinationTable data={filteredFlows} {...context} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OriginDestinationListForm;
