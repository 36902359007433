import {
  Autocomplete,
  FacilitiesLegend,
  FacilitiesToggle,
  dashboardTabsStatus,
  useAmplitudeTracker,
  useFacilities,
  useGeoveloMap,
} from '@geovelo-frontends/commons';
import { ArrowBackIosNew } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import ColorLegendSlider, { ColorLegend } from '../../components/color-legend';
import { IPeriodFormProps } from '../../components/form/period';
import Slider from '../../components/form/slider';
import IncidentsToggle from '../../components/map/incidents-toggle';
import MapLoader from '../../components/map/loader';
import ParkingsToggles from '../../components/map/parkings-toggles';
import { Toggle } from '../../components/map/toggle';
import { colors as averageSpeedsColors } from '../../hooks/map/average-speeds';
import { colors as cyclabilityColors } from '../../hooks/map/cyclability';
import { originDestinationColors } from '../../hooks/map/origin-destination-flows';
import { defaultColors as roadsQualityColors } from '../../hooks/map/roads-quality';
import {
  colors as roadsUsesColors,
  colorsComparison as roadsUsesColorsComparison,
} from '../../hooks/map/roads-uses';
import {
  TAverageSpeedsTabContext,
  TOldOriginDestinationTabContext,
  TOriginDestinationTabContext,
  TRoadsUseTabContext,
  TStoppingAreasTabContext,
} from '../../pages/bicycle-observatory/context';
import {
  TAccidentologyTabContext,
  TCyclabilityTabContext,
  TDiscontinuityTabContext,
  TRoadsQualityTabContext,
} from '../../pages/cartographic-data/context';
import { accidentologyColors } from '../../pages/cartographic-data/hooks/accidentology';
import { IDashboardPage } from '../../pages/dashboard-page';

import { THeaderContext } from './header';

const mapId = 'map';

export type TOutletContext = {
  contentType: 'leftPanel' | 'main' | 'rightPanel';
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

function PageContainerLayout<IPageContext>({
  page: { tabs },
  context,
}: {
  children?: ReactNode;
  context?: IPageContext & {
    accidentology?: TAccidentologyTabContext;
    averageSpeeds?: TAverageSpeedsTabContext;
    cyclability?: TCyclabilityTabContext;
    discontinuity?: TDiscontinuityTabContext;
    header?: THeaderContext;
    originDestination?: TOriginDestinationTabContext;
    oldOriginDestination?: TOldOriginDestinationTabContext;
    period?: IPeriodFormProps;
    roadsQuality?: TRoadsQualityTabContext;
    roadsUse?: TRoadsUseTabContext;
    stoppingAreas?: TStoppingAreasTabContext;
  };
  page: IDashboardPage;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    navigation: { currentTab },
    user: { current: currentUser },
    partner: { current: currentPartner },
    map: {
      facilitiesShowed,
      countersShowed,
      parkingsToggleEnabled,
      parkingRequestsShowed,
      potholesShowed,
      stoppingAreasShowed,
      averageSpeedsShowed,
      accidentZonesShowed,
      incidentsShowed,
      originDestinationZonesShowed,
      originDestinationFlowsShowed,
      baseLayer,
    },
    actions: {
      setCurrentTab,
      setBaseLayer,
      setCurrentMap,
      toggleFacilities: toggleFacilitiesOnMap,
      toggleCounters,
      toggleParkingRequests,
      togglePotholes,
      toggleStoppingAreas,
      toggleAverageSpeeds,
      toggleAccidentZones,
      toggleOriginDestinationZones,
      toggleOriginDestinationFlows,
    },
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { trackEvent } = useAmplitudeTracker();
  const {
    map,
    baseLayer: currentBaseLayer,
    init: initMap,
    destroy: destroyMap,
    setBaseLayer: setMapBaseLayer,
  } = useGeoveloMap({
    baseLayers: ['geovelo', 'dark', 'aerial'],
    theme,
    baseLayer: 'geovelo',
    baseLayersPlacement: 'top-right',
  });
  const {
    initialized: facilitiesInitialized,
    init: initFacilities,
    toggle: toggleFacilities,
    destroy: destroyFacilities,
  } = useFacilities(map);
  const [frequenciesColors, setFrequenciesColors] = useState(
    baseLayer === 'dark' ? roadsUsesColors : [...roadsUsesColors].reverse(),
  );

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    setFrequenciesColors(baseLayer === 'dark' ? roadsUsesColors : [...roadsUsesColors].reverse());
  }, [baseLayer]);

  useEffect(() => {
    if (currentPartner && tabs) {
      const tab =
        tabs.length === 1
          ? tabs[0]
          : tabs.find(
              ({ key, path }) =>
                currentPartner.dashboardTabsPermissions[key] !== 'none' &&
                path === location.pathname.slice(location.pathname.lastIndexOf('/') + 1),
            );

      if (tab) {
        setCurrentTab(tab);

        return;
      }

      const redirectTab = tabs.find(
        ({ key }) => currentPartner.dashboardTabsPermissions[key] !== 'none',
      );

      if (redirectTab) navigate(redirectTab.path);
      else navigate(`/${currentPartner.code}`);
    }

    return () => setCurrentTab(undefined);
  }, [location.pathname]);

  useEffect(() => {
    if (currentTab && currentUser && currentPartner) {
      let pathname = window.location.pathname;
      const index = window.location.pathname.indexOf(currentPartner.code);
      if (index > -1) {
        pathname = pathname.substring(index + currentPartner.code.length);
      }

      trackEvent('Page Visited', {
        pathname,
        partner_id: currentPartner.id,
        partner_code: currentPartner.code,
      });
    }
  }, [currentTab, currentUser, currentPartner]);

  useEffect(() => {
    if (initialized && currentPartner && currentTab?.enableMap) {
      const { north, east, south, west } = currentPartner.bounds;

      initMap({
        container: mapId,
        bounds: [west, south, east, north],
        zoomControlsPosition: 'bottom-right',
        baseLayersControl: true,
        baseLayer: currentTab.baseLayer || 'geovelo',
      });
    } else {
      destroyMap();
      destroyFacilities();
      setBaseLayer('geovelo');
    }
  }, [initialized, currentTab]);

  useEffect(() => {
    setCurrentMap(map);
    map?.setPadding({ top: 100, right: 50, bottom: 100, left: 50 });
  }, [map]);

  useEffect(() => {
    setBaseLayer(currentBaseLayer);
  }, [currentBaseLayer]);

  useEffect(() => {
    if (!map || !currentTab?.enableMap) return;

    const { enableFacilities, hasFacilitiesToggle } = currentTab;

    if (map && !facilitiesInitialized && (enableFacilities || hasFacilitiesToggle)) {
      initFacilities(enableFacilities || facilitiesShowed);
    } else if (facilitiesInitialized)
      toggleFacilities(enableFacilities || (hasFacilitiesToggle && facilitiesShowed) || false);

    if (location.state?.disableBaseLayerUpdate) {
    } else if (currentTab.baseLayer && currentTab.baseLayer !== currentBaseLayer) {
      setMapBaseLayer(currentTab.baseLayer);
    } else if (!currentTab.baseLayer && currentTab.baseLayer !== 'geovelo') {
      setMapBaseLayer('geovelo');
    }
  }, [map, currentTab, facilitiesShowed]);

  const leftPanelContext: TOutletContext = {
    contentType: 'leftPanel',
    loading,
    setLoading,
    ...context,
  };
  const mainContext: TOutletContext = { contentType: 'main', loading, setLoading, ...context };
  const rightPanelContext: TOutletContext = {
    contentType: 'rightPanel',
    loading,
    setLoading,
    ...context,
  };

  if (!currentPartner || !currentTab) return <></>;

  return (
    <>
      {!currentTab.disableLeftPanel && (
        <Box
          bgcolor={currentTab.whiteBackground ? '#FFF' : '#F6F7FB'}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          display="flex"
          flexDirection="column"
          flexShrink={0}
          sx={{ overflowY: 'hidden' }}
          width={526}
          zIndex={2}
        >
          <Box
            flexShrink={0}
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
          >
            <Box alignItems="center" display="flex" gap={2} padding={3}>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                flexGrow={1}
                gap={2}
                overflow="hidden"
              >
                {context?.header?.prevButtonClick && (
                  <IconButton onClick={() => context.header?.prevButtonClick?.()}>
                    <ArrowBackIosNew color="primary" fontSize="small" />
                  </IconButton>
                )}
                <Tooltip title={context?.header?.title || <Trans i18nKey={currentTab.titleKey} />}>
                  <Typography
                    noWrap
                    component="h2"
                    fontWeight="700"
                    lineHeight="2.5rem"
                    variant="h5"
                  >
                    {context?.header?.title || <Trans i18nKey={currentTab.titleKey} />}
                  </Typography>
                </Tooltip>
                {dashboardTabsStatus[currentTab.key] === 'alpha' ? (
                  <Chip
                    color="primary"
                    label="Alpha"
                    size="small"
                    sx={{ color: 'inherit' }}
                    variant="outlined"
                  />
                ) : dashboardTabsStatus[currentTab.key] === 'beta' ? (
                  <Chip
                    color="primary"
                    label="Beta"
                    size="small"
                    sx={{ color: 'inherit' }}
                    variant="outlined"
                  />
                ) : (
                  dashboardTabsStatus[currentTab.key] === 'old' && (
                    <Chip
                      color="primary"
                      label="Old"
                      size="small"
                      sx={{ color: 'inherit' }}
                      variant="outlined"
                    />
                  )
                )}
              </Box>
              {context?.header?.actions && (
                <Box display="flex" flexShrink={0} gap={1}>
                  {context.header.actions}
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexGrow={1} sx={{ overflowY: 'auto' }}>
            <Outlet context={leftPanelContext} />
          </Box>
        </Box>
      )}
      {currentTab.enableMap ? (
        <Box flexGrow={1} position="relative">
          <StyledMap height="100%" id={mapId} position="relative" width="100%">
            <TogglesWrapper>
              {currentTab.hasFacilitiesToggle &&
                (currentTab.hasFacilitiesLegend || currentTab.key === 'osmReports' ? (
                  <Toggle
                    label={<Trans i18nKey="commons.facilities_label" />}
                    showed={facilitiesShowed}
                    toggle={toggleFacilitiesOnMap}
                  />
                ) : (
                  <FacilitiesToggle
                    elevation={0}
                    facilitiesShowed={facilitiesShowed}
                    sx={{ borderRadius: '8px !important', maxHeight: '100%', overflow: 'hidden' }}
                    toggleFacilities={toggleFacilitiesOnMap}
                  />
                ))}
              {currentTab.hasCountersToggle &&
                currentPartner.dashboardTabsPermissions.usagePointAttendance !== 'none' && (
                  <Toggle
                    label={<Trans i18nKey="cycling-insights.usage.point_attendance.counters" />}
                    showed={countersShowed}
                    toggle={toggleCounters}
                  />
                )}
              {currentTab.hasPotholesToggle &&
                currentPartner.dashboardTabsPermissions.facilitiesReports !== 'none' && (
                  <Toggle
                    label={<Trans i18nKey="cycling-insights.facilities.roads_quality.potholes" />}
                    showed={potholesShowed}
                    toggle={togglePotholes}
                  />
                )}
              {currentTab.hasParkingsToggles && parkingsToggleEnabled && (
                <ParkingsToggles simplified={currentTab.key === 'osmReports'} />
              )}
              {currentTab.hasParkingRequestsToggle && (
                <Toggle
                  info={
                    <Trans i18nKey="cycling-insights.cartographic_data.parkings.caption.requests_info" />
                  }
                  label={
                    <Trans i18nKey="cycling-insights.cartographic_data.parkings.caption.requests" />
                  }
                  showed={parkingRequestsShowed}
                  toggle={toggleParkingRequests}
                />
              )}
              {currentTab.hasAverageSpeedsToggle && (
                <Toggle
                  label={
                    <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.average_speeds" />
                  }
                  showed={averageSpeedsShowed}
                  toggle={toggleAverageSpeeds}
                />
              )}
              {currentTab.hasStoppingAreasToggle && (
                <Toggle
                  label={
                    <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.stopping_areas" />
                  }
                  showed={stoppingAreasShowed}
                  toggle={toggleStoppingAreas}
                />
              )}
              {currentTab.hasAccidentZonesToggle && (
                <>
                  <Toggle
                    label={
                      <Trans i18nKey="cycling-insights.usage.accidentology.accident_zones.label" />
                    }
                    showed={accidentZonesShowed}
                    toggle={toggleAccidentZones}
                  />
                  <IncidentsToggle />
                </>
              )}
              {currentTab.hasOriginDestinationToggle && (
                <>
                  <Toggle
                    label={<Trans i18nKey="commons.administrative_levels.zone_other" />}
                    showed={originDestinationZonesShowed}
                    toggle={toggleOriginDestinationZones}
                  >
                    <Box width={200}>
                      <ColorLegend
                        uniform
                        bounds={context?.originDestination?.bounds}
                        colors={originDestinationColors}
                      />
                    </Box>
                  </Toggle>
                  <Toggle
                    label={
                      <Trans i18nKey="cycling-insights.usage.origin_destination.flows_label" />
                    }
                    showed={originDestinationFlowsShowed}
                    toggle={toggleOriginDestinationFlows}
                  />
                </>
              )}
            </TogglesWrapper>
            <LegendsWrapper>
              {(currentTab.hasFrequenciesLegend ||
                (currentTab.hasFrequenciesSlider && !!context?.roadsUse?.setCurrentRange)) && (
                <Box align-items="flex-start" display="flex" flexWrap="wrap" gap={1}>
                  {currentTab.hasFrequenciesLegend && (
                    <ColorLegendSlider
                      uniform
                      bounds={context?.roadsUse?.bounds}
                      caption={
                        context?.roadsUse?.data && (
                          <>
                            {context?.roadsUse?.bounds ? (
                              <>
                                <Typography variant="caption">
                                  {context.roadsUse.bounds.min}
                                </Typography>
                                <Typography variant="caption">
                                  {context.roadsUse.bounds.max}
                                </Typography>
                              </>
                            ) : (
                              [0, 1].map((key) => <Skeleton key={key} variant="text" width={24} />)
                            )}
                          </>
                        )
                      }
                      colors={
                        context?.period?.comparisonEnabled
                          ? context?.roadsUse?.quartiles
                            ? roadsUsesColorsComparison
                                .slice(0, context.roadsUse.quartiles.length - 1)
                                .map(({ value }, index) => ({
                                  value,
                                  min: context?.roadsUse?.quartiles?.[index],
                                  max: context?.roadsUse?.quartiles?.[index + 1],
                                }))
                            : roadsUsesColorsComparison
                          : context?.roadsUse?.quartiles
                            ? frequenciesColors
                                .slice(0, context.roadsUse.quartiles.length - 1)
                                .map(({ value }, index) => ({
                                  value,
                                  min: context?.roadsUse?.quartiles?.[index],
                                  max: context?.roadsUse?.quartiles?.[index + 1],
                                }))
                            : frequenciesColors
                      }
                      title={
                        <Typography component="p" fontSize="0.875rem" fontWeight={600}>
                          <Trans
                            i18nKey={`commons.stats.${
                              currentPartner.dashboardTabsPermissions.usageRoadsUse !==
                              'extrapolated'
                                ? 'roads_uses_label'
                                : 'extrapolation_label'
                            }`}
                          />
                        </Typography>
                      }
                    />
                  )}
                  {currentTab.hasFrequenciesSlider && !!context?.roadsUse?.setCurrentRange && (
                    <Card elevation={0} sx={{ borderRadius: 2, paddingX: 2, paddingY: 1 }}>
                      <Slider
                        bounds={context?.roadsUse?.bounds}
                        currentRange={context?.roadsUse?.currentRange}
                        eventValue="Frequency"
                        label={
                          <Trans
                            i18nKey={`commons.form.${
                              currentPartner.dashboardTabsPermissions.usageRoadsUse !==
                              'extrapolated'
                                ? 'filtering_by_frequency'
                                : 'filtering_by_extrapolation'
                            }`}
                          />
                        }
                        setCurrentRange={context?.roadsUse?.setCurrentRange}
                        step={1}
                      />
                    </Card>
                  )}
                </Box>
              )}
              {currentTab.hasStoppingAreasSlider &&
                !!context?.stoppingAreas?.setCurrentRange &&
                (!currentTab.hasStoppingAreasToggle || stoppingAreasShowed) && (
                  <Box bgcolor="white" borderRadius={2} padding={2}>
                    <Slider
                      bounds={context?.stoppingAreas?.bounds}
                      currentRange={context?.stoppingAreas?.currentRange}
                      eventValue="Stopping Areas Count"
                      label={<Trans i18nKey="commons.form.filtering_by_stopping_areas_count" />}
                      setCurrentRange={context?.stoppingAreas?.setCurrentRange}
                      step={1}
                    />
                    <Slider
                      bounds={context?.stoppingAreas?.secondaryBounds}
                      currentRange={context?.stoppingAreas?.secondaryRange}
                      eventValue="Stopping Areas Time"
                      label={<Trans i18nKey="commons.form.filtering_by_stopping_areas_duration" />}
                      setCurrentRange={context?.stoppingAreas?.setSecondaryRange}
                      step={1}
                    />
                  </Box>
                )}
              {(currentTab.hasSpeedsLegend ||
                (currentTab.hasSpeedsSlider && !!context?.averageSpeeds?.setCurrentRange)) &&
                (!currentTab.hasAverageSpeedsToggle || averageSpeedsShowed) && (
                  <Box align-items="flex-start" display="flex" flexWrap="wrap" gap={1}>
                    {currentTab.hasSpeedsLegend && (
                      <ColorLegendSlider
                        uniform
                        bounds={context?.averageSpeeds?.bounds}
                        caption={
                          context?.averageSpeeds?.quartiles ? (
                            <>
                              <Typography variant="caption">
                                {`< ${Math.round(context.averageSpeeds.quartiles[1] * 10) / 10} km/h`}
                              </Typography>
                              <Typography variant="caption">
                                {`${Math.round(context.averageSpeeds.quartiles[1] * 10) / 10} - ${
                                  context.period?.comparisonEnabled ? '+' : ''
                                }${Math.round(context.averageSpeeds.quartiles[2] * 10) / 10} km/h`}
                              </Typography>
                              <Typography variant="caption">
                                {`> ${context.period?.comparisonEnabled ? '+' : ''}${
                                  Math.round(context.averageSpeeds.quartiles[2] * 10) / 10
                                } km/h`}
                              </Typography>
                            </>
                          ) : (
                            [0, 1, 2].map((key) => <Skeleton key={key} variant="text" width={24} />)
                          )
                        }
                        colors={
                          context?.averageSpeeds?.quartiles
                            ? averageSpeedsColors
                                .slice(0, context.averageSpeeds.quartiles.length - 1)
                                .map(({ value }, index) => ({
                                  value,
                                  min: context?.averageSpeeds?.quartiles?.[index],
                                  max: context?.averageSpeeds?.quartiles?.[index + 1],
                                }))
                            : averageSpeedsColors
                        }
                        title={
                          <Typography component="p" fontSize="0.875rem" fontWeight={600}>
                            <Trans
                              i18nKey={`commons.stats.${
                                context?.period?.comparisonEnabled
                                  ? 'comparison_speed_label'
                                  : 'average_speed_label'
                              }`}
                            />
                          </Typography>
                        }
                      />
                    )}
                    {currentTab.hasSpeedsSlider && !!context?.averageSpeeds?.setCurrentRange && (
                      <Box bgcolor="white" borderRadius={2} padding={2}>
                        <Slider
                          bounds={context?.averageSpeeds?.bounds}
                          currentRange={context?.averageSpeeds?.currentRange}
                          eventValue="Speed"
                          label={<Trans i18nKey="commons.form.filtering_by_speed" />}
                          setCurrentRange={context?.averageSpeeds?.setCurrentRange}
                          step={1}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              {currentTab.hasAccidentologyLegend && (
                <Box display="flex" gap={2}>
                  <ColorLegendSlider
                    uniform
                    caption={
                      <>
                        <Typography variant="caption">
                          <Trans i18nKey="cycling-insights.usage.accidentology.dangerousnesses.high" />
                        </Typography>
                        <Typography variant="caption">
                          <Trans i18nKey="cycling-insights.usage.accidentology.dangerousnesses.medium" />
                        </Typography>
                        <Typography variant="caption">
                          <Trans i18nKey="cycling-insights.usage.accidentology.dangerousnesses.low" />
                        </Typography>
                      </>
                    }
                    colors={accidentologyColors}
                    title={
                      <Typography component="p" fontSize="0.875rem" fontWeight={600}>
                        <Trans i18nKey="cycling-insights.usage.accidentology.legend.title" />
                      </Typography>
                    }
                  />
                  {(incidentsShowed.blackSpots ||
                    incidentsShowed.reports ||
                    incidentsShowed.suddenBrakings ||
                    Object.values(incidentsShowed.accidents).includes(true)) && (
                    <Box
                      bgcolor="white"
                      borderRadius={2}
                      display="flex"
                      flexDirection="column"
                      flexShrink={0}
                      gap={1}
                      height={74}
                      padding={2}
                    >
                      <Typography component="p" fontSize="0.875rem" fontWeight={600}>
                        <Trans i18nKey="cycling-insights.usage.accidentology.legend.title_incidents" />
                      </Typography>
                      <Box alignItems="flex-end" display="flex" gap={2}>
                        <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
                          <Box bgcolor="#F56B84" borderRadius={12} height={24} width={24} />
                          <Typography noWrap variant="caption">
                            <Trans i18nKey="cycling-insights.usage.accidentology.accidents.label" />
                          </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
                          <Box bgcolor="#7E48F0" borderRadius={12} height={24} width={24} />
                          <Typography noWrap variant="caption">
                            <Trans i18nKey="cycling-insights.usage.accidentology.geovelo_reports.label" />
                          </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
                          <Box bgcolor="#3E7BDF" borderRadius={5} height={10} width={10} />
                          <Typography noWrap variant="caption">
                            <Trans i18nKey="cycling-insights.usage.accidentology.sudden_brakings.label" />
                          </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
                          <Box bgcolor="#FFD12F" borderRadius={2} height={4} width={4} />
                          <Typography noWrap variant="caption">
                            <Trans i18nKey="cycling-insights.usage.accidentology.black_spots.label" />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {currentTab.hasRoadsQualityLegend && (
                <ColorLegendSlider
                  uniform
                  bounds={context?.roadsQuality?.bounds}
                  caption={
                    <>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.bad" />
                      </Typography>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.medium" />
                      </Typography>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.good" />
                      </Typography>
                    </>
                  }
                  colors={roadsQualityColors}
                  title={
                    <Typography component="p" fontSize="0.875rem" fontWeight={600}>
                      <Trans i18nKey="commons.permissions.roads_quality" />
                    </Typography>
                  }
                />
              )}
              {currentTab.hasCyclabilityLegend && (
                <ColorLegendSlider
                  uniform
                  bounds={context?.cyclability?.bounds}
                  caption={
                    <>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.very_bad" />
                      </Typography>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.bad" />
                      </Typography>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.good" />
                      </Typography>
                      <Typography variant="caption">
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.very_good" />
                      </Typography>
                    </>
                  }
                  colors={cyclabilityColors}
                  currentRange={context?.cyclability?.currentRange}
                  setCurrentRange={context?.cyclability?.setCurrentRange}
                  sliderLabels={
                    <Box display="flex" marginTop="-6px" width={306}>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="flex-start"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.very_bad" />
                      </Typography>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="center"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.bad" />
                      </Typography>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="center"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.good" />
                      </Typography>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="flex-end"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.cyclability.caption.very_good" />
                      </Typography>
                    </Box>
                  }
                  sliderTitle={<Trans i18nKey="commons.actions.filter" />}
                  title={
                    <Typography component="p" fontSize="0.875rem" fontWeight={600}>
                      <Trans i18nKey="cycling-insights.facilities.cyclability.caption.title" />
                    </Typography>
                  }
                />
              )}
              {currentTab.hasFacilitiesLegend &&
                (!currentTab.hasFacilitiesToggle || facilitiesShowed) && (
                  <Card elevation={0} sx={{ borderRadius: 2, paddingX: 2, paddingY: 1 }}>
                    <FacilitiesLegend row />
                  </Card>
                )}
            </LegendsWrapper>
            {currentTab.hasOriginDestinationSlider &&
              !!context?.oldOriginDestination?.setCurrentRange && (
                <Box
                  bgcolor="white"
                  borderRadius={2}
                  bottom={16}
                  left={16}
                  padding={2}
                  position="absolute"
                  zIndex={2}
                >
                  <Slider
                    disableRange
                    bounds={context.oldOriginDestination.bounds}
                    currentRange={context.oldOriginDestination.currentRange}
                    eventValue="Count"
                    label={
                      <Trans
                        components={[<span key={0} style={{ color: '#3E7BDF' }} />]}
                        i18nKey="cycling-insights.usage.origin_destination.filter"
                        values={{ count: context.oldOriginDestination.currentRange?.[1] || 1 }}
                      />
                    }
                    setCurrentRange={context.oldOriginDestination.setCurrentRange}
                    step={
                      !context.oldOriginDestination.bounds?.max ||
                      context.oldOriginDestination.bounds.max < 100
                        ? 1
                        : 10
                    }
                  />
                </Box>
              )}
            {currentTab.hasDiscontinuitySlider &&
            context?.discontinuity?.data &&
            context?.discontinuity?.setCurrentRange ? (
              <Box
                bgcolor="white"
                borderRadius={2}
                bottom={16}
                left={16}
                padding={2}
                position="absolute"
                zIndex={2}
              >
                <Slider
                  disableRange
                  bounds={context.discontinuity.bounds}
                  currentRange={context.discontinuity.currentRange}
                  eventValue="Count"
                  label={
                    <Trans
                      components={[<span key={0} style={{ color: '#d73027' }} />]}
                      i18nKey="cycling-insights.facilities.discontinuity.filter"
                      values={{ count: context.discontinuity.currentRange?.[1] || 1 }}
                    />
                  }
                  setCurrentRange={context.discontinuity.setCurrentRange}
                  step={
                    !context.discontinuity.bounds?.max || context.discontinuity.bounds.max < 100
                      ? 1
                      : 10
                  }
                />
              </Box>
            ) : (
              <></>
            )}
            {currentTab.hasRoadsQualitySlider && !!context?.roadsQuality?.setCurrentRange && (
              <Box
                bgcolor="white"
                borderRadius={2}
                bottom={16}
                left="334px"
                padding={2}
                position="absolute"
                zIndex={2}
              >
                <Slider
                  bounds={{ min: 0, max: 3 }}
                  currentRange={context?.roadsQuality?.currentRange}
                  customLabels={
                    <Box display="flex" marginTop="-6px" width={200}>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="flex-start"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.bad" />
                      </Typography>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="center"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.medium" />
                      </Typography>
                      <Typography
                        display="flex"
                        flex="1 1 0px"
                        justifyContent="flex-end"
                        lineHeight={1.43}
                        variant="caption"
                      >
                        <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.good" />
                      </Typography>
                    </Box>
                  }
                  eventValue="Roads Quality"
                  label={<Trans i18nKey="commons.actions.filter" />}
                  setCurrentRange={context?.roadsQuality?.setCurrentRange}
                  step={1}
                />
              </Box>
            )}
            {currentTab.key === 'osmReports' && (
              <StyledAutocomplete
                alignItems="center"
                bgcolor="white"
                borderRadius={2}
                display="flex"
                height={48}
                left={16}
                paddingX={2}
                position="absolute"
                top={10}
                width={300}
                zIndex={2}
              >
                <Autocomplete
                  disableFloatingLabel
                  defaultValue={null}
                  id="address-search"
                  inputProps={{ variant: 'standard' }}
                  onSelect={(place) =>
                    place &&
                    map?.flyTo({
                      center: [place.point.coordinates[0], place.point.coordinates[1]],
                      zoom: 18,
                    })
                  }
                />
              </StyledAutocomplete>
            )}
            {currentTab.enableRightPanel && (
              <Box
                display="flex"
                flexDirection="column"
                maxHeight={currentTab.rightPanelProps?.maxHeight || 'calc(100% - 150px)'}
                maxWidth="calc(100% - 32px)"
                position="absolute"
                right={16}
                top={72}
                width={currentTab.rightPanelProps?.width || 400}
                zIndex={1001}
              >
                <Outlet context={rightPanelContext} />
              </Box>
            )}
            {loading && <MapLoader />}
          </StyledMap>
          {currentTab.enableMapCanvas && <Outlet context={mainContext} />}
        </Box>
      ) : (
        tabs && <Outlet context={mainContext} />
      )}
    </>
  );
}

const StyledMap = styled(Box)`
  .map-primary-tooltip.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
    border-top-color: ${({ theme }) => theme.palette.primary.light};
  }

  .map-secondary-tooltip.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
    border-top-color: #e9570d;
  }
`;

const TogglesWrapper = styled.div`
  align-items: flex-start;
  bottom: 44px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  left: 16px;
  pointer-events: none;
  position: absolute;
  right: 60px;
  top: 10px;
  z-index: 2;

  > div {
    pointer-events: all;
  }
`;

const LegendsWrapper = styled.div`
  align-items: flex-start;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: none;
  position: absolute;
  right: 60px;
  left: 16px;
  z-index: 2;

  > div {
    pointer-events: all;
  }
`;

const StyledAutocomplete = styled(Box)`
  && {
    .MuiFormControl-root {
      margin: 0;
    }

    .MuiInputBase-root {
      &::before,
      &::after {
        display: none;
      }

      .MuiInputBase-input {
        margin-top: 3px;
      }
    }
  }
`;

export default PageContainerLayout;
