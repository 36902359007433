import {
  SuddenBrakingService,
  currentMonth,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import ColorLegendSlider from '../../../../components/color-legend';
import PeriodForm from '../../../../components/form/period';
import TabIntroduction from '../../../../components/tab-introduction';
import useSuddenBrakings, { defaultColors } from '../../../../hooks/map/sudden-brakings';
import usePeriod from '../../../../hooks/period';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';

function SuddenBrakingsForm({
  defaultPeriods,
  period,
  suddenBrakings: { data, bounds, secondaryBounds, setData, setBounds, setSecondaryBounds },
  setLoading,
}: IBicycleObservatoryPageContext & TOutletContext): JSX.Element {
  const {
    map: { current: currentMap, baseLayer },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { getTitle: getPeriodTitle } = usePeriod();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useSuddenBrakings(currentMap);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (period.values.current.type !== 'month') {
      period.setValues({
        ...period.values,
        current: currentMonth,
        prev: currentMonth.getPrevPeriod(),
      });
    }

    return () => {
      cancelPromises();
      setData(undefined);
      setBounds(undefined);
      setSecondaryBounds(undefined);
      setLoading(false);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentMap) initLayers();

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    getData();
  }, [period.values]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    if (data && bounds) {
      timeoutRef.current = setTimeout(() => {
        const colors = baseLayer === 'dark' ? defaultColors : [...defaultColors].reverse();
        updateLayers(data, {
          colors,
          primaryBounds: bounds,
          secondaryBounds: secondaryBounds,
        });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        { primaryBounds: { min: 0, max: 1 } },
      );
    }
  }, [baseLayer, layersInitialized, data]);

  async function getData() {
    cancelPromises();
    setData(undefined);
    setBounds(undefined);
    setSecondaryBounds(undefined);

    if (!currentPartner) return;

    const {
      values: { current: currentPeriod },
    } = period;

    let cancelled = false;
    setLoading(true);

    try {
      const data = await cancellablePromise(
        SuddenBrakingService.getSuddenBrakings({
          partner: currentPartner,
          period: currentPeriod.toIPeriod(),
          dayPeriod: 'all',
        }),
      );

      let max = 1;
      let secondaryMax = 0;
      let secondaryMin = Infinity;

      data.features.forEach(({ properties }) => {
        max = Math.min(max, properties.average);
        secondaryMax = Math.max(secondaryMax, properties.nb_hard_braking);
        secondaryMin = Math.min(secondaryMin, properties.nb_hard_braking);
      });

      setBounds({ min: -200, max });
      setSecondaryBounds({ min: secondaryMin, max: secondaryMax });
      setData(data);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getPeriodTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        setBounds({ min: 0, max: 1 });
        setSecondaryBounds(undefined);
        setData(null);
      } else cancelled = true;
    }

    if (!cancelled) setLoading(false);
  }

  const colors = baseLayer === 'dark' ? defaultColors : [...defaultColors].reverse();

  return (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.sudden_brakings" />
      <PeriodForm
        {...period}
        disableComparison
        comparisonEnabled={false}
        customPeriodTypes={{ defaultPeriods, enabledTypes: ['month'] }}
      />
      <Box flexGrow={1} />
      <ColorLegendSlider
        uniform
        bounds={bounds}
        colors={colors}
        title={
          <>
            <Typography align="center" component="p" variant="caption">
              <Trans i18nKey="cycling-insights.usage.sudden_brakings.form.intensity_legend" />
            </Typography>
            <Typography align="center" component="p" fontSize="0.65rem">
              <Trans i18nKey="cycling-insights.usage.sudden_brakings.form.intensity_legend_note" />
            </Typography>
          </>
        }
      />
    </Box>
  );
}

export default SuddenBrakingsForm;
