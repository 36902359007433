import {
  CyclabilityZoneService,
  Flow,
  OriginDestinationService,
  TDayPeriod,
  TTimePeriod,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { InfoOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import illuFlux from '../../../../assets/images/illu-flux.png';
import Button from '../../../../components/button';
import PeriodForm from '../../../../components/form/period';
import LinkCard from '../../../../components/link-card';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useOriginDestinationFlows from '../../../../hooks/map/old-origin-destination-flows';
import { TOutletContext } from '../../../../layouts/page/container';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';
import { IBicycleObservatoryPageContext } from '../../context';

import OriginDestinationChart from './chart';

export const periodProps: {
  all: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
  work: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
  leisure: { timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }[];
} = {
  all: [{ timePeriod: 'all_day', dayPeriod: 'all' }],
  work: [{ timePeriod: 'peak', dayPeriod: 'week' }],
  leisure: [
    { timePeriod: 'off_peak', dayPeriod: 'week' },
    { timePeriod: 'all_day', dayPeriod: 'weekend' },
  ],
};

function OriginDestinationForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    defaultPeriods,
    period,
    header: { setPrevButtonClick, setTitle },
    oldOriginDestination: {
      canvasRef,
      zones,
      externalZones,
      flows,
      currentRange,
      selectedZoneId,
      setCurrentRange,
      setZones,
      setExternalZones,
      setFlows,
      setBounds,
      selectZoneId,
    },
    setLoading,
  } = context;
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { cancellablePromise: cancellableListPromise, cancelPromises: cancelListPromises } =
    useCancellablePromise();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    clear: clearLayers,
  } = useOriginDestinationFlows(currentMap, {
    setCyclabilityZoneId: (id) => {
      selectZoneId(selectedZoneId !== id ? id : null);
    },
  });
  const [rankingType, setRankingType] = useState<'all' | 'work' | 'leisure'>('all');
  const [flowsCount, setFlowsCount] = useState<number>();
  const [listFlows, setListFlows] = useState<Flow[]>();

  useEffect(() => {
    period.enableComparison?.(false);
    period.setValues({ ...period.values, timePeriod: 'all_day', dayPeriod: 'all' });
    selectZoneId(null);
    getZones();

    return () => {
      cancelPromises();
      cancelListPromises();
      setZones(undefined);
      setExternalZones(undefined);
      setFlows(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
      setLoading(false);
      setPrevButtonClick(undefined);
      setTitle(undefined);
    };
  }, []);

  useEffect(() => {
    if (selectedZoneId !== null) {
      const zone = zones?.find(({ id }) => id === selectedZoneId);
      if (zone) {
        setPrevButtonClick(() => () => selectZoneId(null));
        setTitle(zone.name);
      }
    } else {
      setPrevButtonClick(undefined);
      setTitle(undefined);
    }
  }, [selectedZoneId]);

  useEffect(() => {
    if (zones) getData();
  }, [period.values, zones]);

  useEffect(() => {
    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (currentMap && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [currentMap, canvasRef.current]);

  useEffect(() => {
    getListData();
  }, [rankingType]);

  useEffect(() => {
    if (layersInitialized) {
      updateLayers(zones, externalZones, flows, currentRange, selectedZoneId);
    }
  }, [layersInitialized, zones, externalZones, flows, currentRange, selectedZoneId]);

  useEffect(() => {
    setFlowsCount(
      flows
        ?.filter(({ origin, destination }) => {
          if (selectedZoneId) return origin === selectedZoneId || destination === selectedZoneId;
          else return true;
        })
        .reduce((res, { count }) => (res += count), 0),
    );
  }, [flows, selectedZoneId]);

  async function getZones() {
    if (!currentPartner) return;
    setLoading(true);
    try {
      const { zones } = await CyclabilityZoneService.getZones({
        administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
        partnerCode: currentPartner.code,
        considerLivingStreets: true,
        rowsPerPage: 100,
        query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
      });
      setZones(zones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  async function getData() {
    cancelPromises();
    setFlows(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);

    if (!currentPartner || !zones) {
      setLoading(false);
      return;
    }

    const {
      values: { current: currentPeriod, timePeriod, dayPeriod },
    } = period;

    if (!timePeriod || !dayPeriod) return;

    let cancelled = false;

    try {
      const zoneIds = zones.map(({ id }) => id);
      const props = { timePeriod, dayPeriod };

      const flows = await cancellablePromise(
        OriginDestinationService.getFlows({
          period: currentPeriod.toIPeriod(),
          departureCyclabilityZoneIds: zoneIds,
          arrivalCyclabilityZoneIds: zoneIds,
          ...toOriginDestinationInput(props),
        }),
      );

      const externalZonesIds = flows.reduce<number[]>((res, { origin, destination }) => {
        if (!zoneIds.includes(origin) && !res.includes(origin)) res.push(origin);
        if (!zoneIds.includes(destination) && !res.includes(destination)) res.push(destination);
        return res;
      }, []);

      const nbPages = Math.ceil(externalZonesIds.length / 100);
      const _externalZones = (
        await cancellablePromise(
          Promise.all(
            new Array(nbPages).fill(null).map((_, pageIndex) =>
              CyclabilityZoneService.getZones({
                administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
                considerLivingStreets: true,
                ids: externalZonesIds.slice(pageIndex * 100, (pageIndex + 1) * 100),
                rowsPerPage: 100,
                query:
                  '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
              }),
            ),
          ),
        )
      ).flatMap(({ zones }) => zones);

      setExternalZones(_externalZones);
      setBounds({ min: 0, max: flows.length });
      setCurrentRange([0, flows.length]);
      setFlows(flows.filter(({ count }) => count > 1));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setBounds({ min: 0, max: 1 });
        setCurrentRange([0, 1]);
        setFlows([]);
      } else cancelled = true;
    }

    if (!cancelled) setLoading(false);
  }

  async function getListData() {
    cancelListPromises();
    setListFlows(undefined);

    if (!currentPartner || !zones || rankingType === 'all') return;

    const {
      values: { current: currentPeriod },
    } = period;

    try {
      const zoneIds = zones.map(({ id }) => id);
      let flows;

      if (rankingType === 'leisure') {
        const [outgoingFlows, weekEndOutgoingFlows] = await cancellableListPromise(
          Promise.all([
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][0]),
            }),
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][1]),
            }),
          ]),
        );
        weekEndOutgoingFlows.forEach((weekEndFlow) => {
          const flow = outgoingFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else outgoingFlows.push(weekEndFlow);
        });

        const outgoingFlowsMap = outgoingFlows.reduce<{
          [key: number]: { [key: number]: true };
        }>((res, { origin, destination }) => {
          if (res[origin]) res[origin][destination] = true;
          else res[origin] = { [destination]: true };

          return res;
        }, {});

        const [incomingFlows, weekEndIncomingFlows] = await cancellableListPromise(
          Promise.all([
            OriginDestinationService.getFlows({
              startId: outgoingFlows.length,
              period: currentPeriod.toIPeriod(),
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][0]),
            }),
            OriginDestinationService.getFlows({
              startId: outgoingFlows.length,
              period: currentPeriod.toIPeriod(),
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps[rankingType][1]),
            }),
          ]),
        );
        weekEndIncomingFlows.forEach((weekEndFlow) => {
          const flow = incomingFlows.find(
            ({ origin, destination }) =>
              origin === weekEndFlow.origin && destination === weekEndFlow.destination,
          );
          if (flow) flow.count += weekEndFlow.count;
          else incomingFlows.push(weekEndFlow);
        });

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      } else {
        const outgoingFlows = await cancellableListPromise(
          OriginDestinationService.getFlows({
            period: currentPeriod.toIPeriod(),
            departureCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(periodProps[rankingType][0]),
          }),
        );

        const outgoingFlowsMap = outgoingFlows.reduce<{ [key: number]: { [key: number]: true } }>(
          (res, { origin, destination }) => {
            if (res[origin]) res[origin][destination] = true;
            else res[origin] = { [destination]: true };

            return res;
          },
          {},
        );

        const incomingFlows = await cancellableListPromise(
          OriginDestinationService.getFlows({
            startId: outgoingFlows.length,
            period: currentPeriod.toIPeriod(),
            arrivalCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(periodProps[rankingType][0]),
          }),
        );

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      }
      setListFlows(flows);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setListFlows([]);
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.origin_destination" />
      <Paper
        header={
          <PeriodForm
            disableComparison
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={{ defaultPeriods, enabledTypes: ['month'] }}
            {...period}
          />
        }
      >
        <OriginDestinationChart {...context} />
      </Paper>
      <Ranking
        disableProgression
        hasPercentageBar
        action={
          <Button
            component={Link}
            endIcon={<KeyboardArrowRight />}
            to="../origin-destination-list"
            variant="text"
          >
            <Trans i18nKey="cycling-insights.usage.origin_destination.all_trips" />
          </Button>
        }
        data={
          zones &&
          (rankingType === 'all' ? flows : listFlows)
            ?.filter(({ origin, destination }) => {
              if (selectedZoneId !== null)
                return (
                  (origin === selectedZoneId || destination === selectedZoneId) &&
                  zones.find(({ id }) => id === origin) &&
                  zones.find(({ id }) => id === destination)
                );
              else
                return (
                  zones.find(({ id }) => id === origin) &&
                  zones.find(({ id }) => id === destination)
                );
            })
            .sort((a, b) => b.count - a.count)
            .slice(0, 5)
            .map(({ id, count, origin, destination }) => {
              return {
                id: id,
                title: (
                  <>
                    <Typography
                      fontSize="1rem"
                      fontWeight={600}
                      maxWidth="calc(50% - 18px)"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      title={zones.find(({ id }) => id === origin)?.name}
                      whiteSpace="nowrap"
                    >
                      {zones.find(({ id }) => id === origin)?.name}
                    </Typography>
                    <Typography fontSize="1rem" fontWeight={600}>
                      &nbsp;{'>'}&nbsp;
                    </Typography>
                    <Typography
                      fontSize="1rem"
                      fontWeight={600}
                      maxWidth="calc(50% - 18px)"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      title={zones.find(({ id }) => id === destination)?.name}
                      whiteSpace="nowrap"
                    >
                      {zones.find(({ id }) => id === destination)?.name}
                    </Typography>
                  </>
                ),
                value: count,
              };
            })
        }
        subtitle={
          <ToggleButtonGroup
            exclusive
            color="primary"
            onChange={(_, value) => value && setRankingType(value)}
            sx={{ marginTop: '16px' }}
            value={rankingType}
          >
            <ToggleButton value="all">
              <Typography fontSize="0.875rem" textTransform="none">
                <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.all" />
              </Typography>
            </ToggleButton>
            <ToggleButton value="work">
              <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
                <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.work" />
              </Typography>
              <Tooltip
                title={
                  <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.work_tooltip" />
                }
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="leisure">
              <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
                <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.leisure" />
              </Typography>
              <Tooltip
                title={
                  <Trans i18nKey="cycling-insights.usage.origin_destination.ranking.trips_types.leisure_tooltip" />
                }
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        }
        title="cycling-insights.usage.origin_destination.ranking.title"
        total={flowsCount}
      />
      <LinkCard
        description={
          <Trans i18nKey="cycling-insights.usage.origin_destination.targeted_analysis.title" />
        }
        icon={illuFlux}
        title={
          <Trans i18nKey="cycling-insights.usage.origin_destination.targeted_analysis.subtitle" />
        }
        to="../origin-destination-analysis"
      />
    </Box>
  );
}

export default OriginDestinationForm;
