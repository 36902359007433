import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import OriginDestinationForm from './form';

function OriginDestinationTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    header: { setActions },
    originDestination: { canvasRef },
  } = context;

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  return (
    <>
      <PageContentLayout
        leftPanel={<OriginDestinationForm {...context} />}
        main={
          <>
            <canvas
              id="origin-destination-deck-canvas"
              ref={canvasRef}
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            />
            <Box id="h3-tooltip" position="absolute" sx={{ pointerEvents: 'none' }} />
          </>
        }
      />
    </>
  );
}

export default OriginDestinationTab;
