import { DashboardPages } from '@geovelo-frontends/commons';
import {
  CheckBoxOutlined,
  DirectionsBike,
  HubOutlined,
  MapOutlined,
  UploadFileOutlined,
} from '@mui/icons-material';

import CommunityIcon from '../components/icons/community';
import DownloadIcon from '../components/icons/download';
import HomeIcon from '../components/icons/home';
import ParkingsIcon from '../components/icons/parkings';

import ApiPage, { ApiKeysTab, AvailabilityTab, DocumentationTab } from './api';
import BicycleObservatoryPage, {
  CountersFormTab,
  CountersListTab,
  ExtrapolationTab,
  FluidityAnalysisTab,
  FluidityTab,
  OldOriginDestinationTab,
  OriginDestinationAnalysisTab,
  OriginDestinationListTab,
  OriginDestinationTab,
  RoadsUseAnalysisTab,
  RoadsUseNewTab,
  ScenariosTab,
  SuddenBrakingsTab,
} from './bicycle-observatory';
import GeoveloActivityTab from './bicycle-observatory/components/geovelo-activity';
import CartographicDataPage, {
  AccidentZoneTab,
  AccidentologyTab,
  CyclabilityTab,
  DiscontinuityAnalysisTab,
  DiscontinuityTab,
  FacilitiesNewReportTab,
  FacilitiesReportsTab,
  FacilitiesStatsTab,
  FacilitiesSuggestionsFormTab,
  FacilitiesSuggestionsTab,
  FacilitiesTab,
  GeoveloRoadsQualityTab,
  IsochronesTab,
  PotholeReportsTab,
  ReportsTab,
  RoadsQualityAnalysisTab,
} from './cartographic-data';
import { IDashboardPage, IDashboardTab } from './dashboard-page';
import DownloadsPage from './downloads';
import GISPage, { SendDataTab } from './gis';
import HomePage from './home';
import ParkingsPage, { ParkingsTab } from './parkings';
import ParkingsReportsTab from './parkings/components/reports';
import ParkingsStatsTab from './parkings/components/stats';
import PromotionPage, {
  AnimationTab,
  ChallengesFormTab,
  ChallengesTab,
  MembersTab,
  NewsFeedTab,
  NewsFormTab,
  RidesTab,
  WidgetsTab,
} from './promotion';
import QAPage, {
  BikeRoutesTab,
  ExclusionZonesFormTab,
  ExclusionZonesTab,
  NewReportTab,
  ReportsTab as OSMReportsTab,
  RefRoutesTab,
} from './qa';
import ItineraryReportsTab from './qa/components/itinerary-reports';
import UsersLogsTab from './qa/components/users-logs';

export const ridesTab: IDashboardTab = {
  key: 'tourismRides',
  path: 'rides',
  TabElement: RidesTab,
  titleKey: 'cycling-insights.tourism.navigation.rides',
  pageViewEventAction: 'View tourism rides page',
  enableMap: true,
};

export const bikeRoutesTab: IDashboardTab = {
  key: 'tourismBikeRoutes',
  path: 'bike-routes',
  TabElement: BikeRoutesTab,
  titleKey: 'cycling-insights.tourism.navigation.bike_routes',
  pageViewEventAction: 'View tourism bike routes page',
};

const pages: Array<IDashboardPage & { PageElement: (props: IDashboardPage) => JSX.Element }> = [
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    index: true,
    pageKey: DashboardPages.Home,
    path: '',
    PageElement: HomePage,
    titleKey: 'commons.navigation.home',
    icon: <HomeIcon />,
  },
  {
    pageKey: DashboardPages.BicycleObservatory,
    path: 'bicycle-observatory',
    PageElement: BicycleObservatoryPage,
    tabs: [
      {
        hasFrequenciesLegend: true,
        hasFrequenciesSlider: true,
        key: 'usageRoadsUse',
        path: 'roads-use',
        TabElement: RoadsUseNewTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.roads_use',
        hasCountersToggle: true,
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View bicycle observatory roads use page',
        baseLayer: 'dark',
      },
      {
        hasFrequenciesLegend: true,
        hasFrequenciesSlider: true,
        key: 'extrapolation',
        path: 'extrapolation',
        TabElement: ExtrapolationTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.extrapolation',
        hasCountersToggle: true,
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View bicycle observatory extrapolation page',
        baseLayer: 'dark',
      },
      {
        hideInDrawer: true,
        key: 'usagePointAttendance',
        path: 'counters',
        TabElement: CountersListTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.point_attendance',
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View cartographic data facilities reports page',
        whiteBackground: true,
        baseLayer: 'dark',
      },
      {
        hasFrequenciesLegend: true,
        hasFrequenciesSlider: true,
        hideInDrawer: true,
        key: 'usageRoadsUseAnalysis',
        path: 'roads-use-analysis',
        TabElement: RoadsUseAnalysisTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.roads_use_analysis',
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View bicycle observatory roads use analysis page',
        baseLayer: 'dark',
      },
      {
        hideInDrawer: true,
        key: 'counterForm',
        path: 'counter-form',
        TabElement: CountersFormTab,
        titleKey: 'cycling-insights.usage.point_attendance.form.title',
        enableMap: true,
        pageViewEventAction: 'View add or edit counter page',
        whiteBackground: true,
      },
      {
        hasSpeedsSlider: true,
        hasSpeedsLegend: true,
        hasStoppingAreasToggle: true,
        hasAverageSpeedsToggle: true,
        hasStoppingAreasSlider: true,
        key: 'usageFluidity',
        path: 'fluidity',
        TabElement: FluidityTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.fluidity',
        enableMap: true,
        pageViewEventAction: 'View bicycle observatory fluidity page',
        baseLayer: 'dark',
      },
      {
        hasSpeedsSlider: true,
        hasSpeedsLegend: true,
        hasStoppingAreasToggle: true,
        hasAverageSpeedsToggle: true,
        hasStoppingAreasSlider: true,
        hideInDrawer: true,
        key: 'usageFluidityAnalysis',
        path: 'fluidity-analysis',
        TabElement: FluidityAnalysisTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.fluidity_analysis',
        enableMap: true,
        pageViewEventAction: 'View bicycle observatory fluidity analysis page',
        baseLayer: 'dark',
      },
      {
        hasOriginDestinationSlider: true,
        key: 'usageOriginDestinationOld',
        path: 'origin-destination',
        TabElement: OldOriginDestinationTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.origin_destination',
        enableMap: true,
        enableMapCanvas: true,
        pageViewEventAction: 'View bicycle observatory origin destination page',
      },
      {
        key: 'usageOriginDestination',
        path: 'new-origin-destination',
        TabElement: OriginDestinationTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.origin_destination',
        enableMap: true,
        enableMapCanvas: true,
        hasOriginDestinationToggle: true,
        pageViewEventAction: 'View bicycle observatory origin destination page',
      },
      {
        hideInDrawer: true,
        hasOriginDestinationSlider: true,
        key: 'usageOriginDestinationAnalysis',
        path: 'origin-destination-analysis',
        TabElement: OriginDestinationAnalysisTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.origin_destination',
        enableMap: true,
        enableMapCanvas: true,
        pageViewEventAction: 'View bicycle observatory origin destination analysis page',
      },
      {
        whiteBackground: true,
        hideInDrawer: true,
        hasOriginDestinationSlider: true,
        key: 'usageOriginDestinationList',
        path: 'origin-destination-list',
        TabElement: OriginDestinationListTab,
        titleKey: 'cycling-insights.usage.origin_destination.all_trips',
        enableMap: true,
        enableMapCanvas: true,
        pageViewEventAction: 'View bicycle observatory origin destination list page',
      },
      {
        key: 'usageActivityStatistics',
        path: 'geovelo-activity',
        TabElement: GeoveloActivityTab,
        titleKey: 'cycling-insights.monitoring_indicators.navigation.geovelo_activity',
        pageViewEventAction: 'View bicycle observatory geovelo activity page',
      },
      {
        key: 'usageSuddenBrakings',
        path: 'sudden-brakings',
        TabElement: SuddenBrakingsTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.sudden_brakings',
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View bicycle observatory sudden brakings page',
        baseLayer: 'dark',
      },
      {
        key: 'usageTravelSimulations',
        path: 'travel-simulations',
        TabElement: ScenariosTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.travel_simulations',
        enableMap: true,
        enableMapCanvas: true,
        pageViewEventAction: 'View bicycle observatory travel simulations page',
      },
    ],
    titleKey: 'commons.navigation.monitoring_indicators',
    descriptionKey: 'commons.navigation.monitoring_indicators_description',
    icon: <DirectionsBike />,
  },
  {
    pageKey: DashboardPages.CartographicData,
    path: 'cartographic-data',
    PageElement: CartographicDataPage,
    tabs: [
      {
        key: 'facilities',
        path: 'facilities',
        TabElement: FacilitiesTab,
        titleKey: 'commons.navigation.facilities',
        enableMap: true,
        enableFacilities: true,
        hasFacilitiesLegend: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View cartographic data facilities page',
      },
      {
        hideInDrawer: true,
        key: 'facilitiesReports',
        path: 'facilities-reports',
        TabElement: FacilitiesReportsTab,
        titleKey: 'cycling-insights.reports.navigation.osm_cartographic_reports',
        enableMap: true,
        enableFacilities: true,
        hasFacilitiesLegend: true,
        enableRightPanel: true,
        rightPanelProps: { width: 350 },
        pageViewEventAction: 'View cartographic data facilities reports page',
        whiteBackground: true,
      },
      {
        hideInDrawer: true,
        key: 'facilitiesStats',
        path: 'facilities-stats',
        TabElement: FacilitiesStatsTab,
        titleKey: 'cycling-insights.facilities.navigation.analysis',
        disableLeftPanel: true,
        pageViewEventAction: 'View cartographic data facilities stats page',
        whiteBackground: true,
      },
      {
        hideInDrawer: true,
        key: 'newReport',
        path: 'new-report',
        TabElement: FacilitiesNewReportTab,
        titleKey: 'cycling-insights.reports.navigation.new_report',
        enableMap: true,
        pageViewEventAction: 'View new contribution page',
        whiteBackground: true,
      },
      {
        hasCyclabilityLegend: true,
        key: 'cyclability',
        path: 'cyclability',
        TabElement: CyclabilityTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.cyclability',
        enableMap: true,
        pageViewEventAction: 'View cartographic data cyclability page',
      },
      {
        key: 'usageAccidentology',
        path: 'accidentology',
        TabElement: AccidentologyTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.accidentology',
        enableMap: true,
        enableMapCanvas: true,
        hasAccidentZonesToggle: true,
        hasAccidentologyLegend: true,
        hasFacilitiesToggle: true,
        hasFacilitiesLegend: true,
        enableRightPanel: true,
        rightPanelProps: { width: 350 },
        pageViewEventAction: 'View cartographic data accidentology page',
        baseLayer: 'dark',
      },
      {
        key: 'usageAccidentologyAccidentZones',
        path: 'accident-zones',
        hideInDrawer: true,
        TabElement: AccidentZoneTab,
        titleKey: 'cycling-insights.usage.accidentology.accident_zones.label',
        enableMap: true,
        enableMapCanvas: true,
        hasAccidentZonesToggle: true,
        hasAccidentologyLegend: true,
        hasFacilitiesToggle: true,
        hasFacilitiesLegend: true,
        enableRightPanel: true,
        rightPanelProps: { width: 350 },
        pageViewEventAction: 'View cartographic data accident zones page',
        baseLayer: 'dark',
      },
      {
        key: 'reports',
        path: 'reports',
        TabElement: ReportsTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.reports',
        enableMap: true,
        hasFacilitiesToggle: true,
        enableRightPanel: true,
        pageViewEventAction: 'View cartographic data reports page',
        whiteBackground: true,
      },
      {
        key: 'facilitiesRoadsQuality',
        path: 'roads-quality-new',
        TabElement: GeoveloRoadsQualityTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.roads_quality',
        enableMap: true,
        hasPotholesToggle: true,
        hasRoadsQualityLegend: true,
        hasRoadsQualitySlider: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View cartographic data roads quality page',
        baseLayer: 'dark',
      },
      {
        hasRoadsQualityLegend: true,
        hasRoadsQualitySlider: true,
        hideInDrawer: true,
        key: 'facilitiesRoadsQualityAnalysis',
        path: 'roads-quality-analysis',
        TabElement: RoadsQualityAnalysisTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.roads_quality_analysis',
        enableMap: true,
        pageViewEventAction: 'View cartographic data roads quality analysis page',
        baseLayer: 'dark',
      },
      {
        hideInDrawer: true,
        key: 'potholeReports',
        path: 'pothole-reports',
        TabElement: PotholeReportsTab,
        titleKey: 'commons.navigation.pothole_reports',
        enableMap: true,
        enableFacilities: true,
        hasFacilitiesLegend: true,
        enableRightPanel: true,
        rightPanelProps: { width: 350 },
        pageViewEventAction: 'View cartographic data facilities reports page',
        whiteBackground: true,
      },
      {
        key: 'facilitiesIsochrones',
        path: 'isochrones',
        TabElement: IsochronesTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.isochrones',
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 'auto' },
        pageViewEventAction: 'View cartographic data isochrones page',
      },
      {
        key: 'discontinuity',
        path: 'discontinuity',
        TabElement: DiscontinuityTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.discontinuity',
        enableMap: true,
        hasFacilitiesToggle: true,
        hasDiscontinuitySlider: true,
        pageViewEventAction: 'View cartographic data discontinuity page',
      },
      {
        key: 'discontinuityAnalysis',
        path: 'discontinuity-analysis',
        TabElement: DiscontinuityAnalysisTab,
        titleKey: 'cycling-insights.bicycle_observatory.navigation.discontinuity',
        enableMap: true,
        hasFacilitiesToggle: true,
        hasDiscontinuitySlider: true,
        hideInDrawer: true,
        pageViewEventAction: 'View cartographic data discontinuity analysis page',
      },
      {
        key: 'facilitiesSuggestions',
        path: 'facilities-suggestions',
        TabElement: FacilitiesSuggestionsTab,
        titleKey: 'cycling-insights.qa.navigation.facilities_suggestions',
        enableMap: true,
        enableFacilities: true,
        pageViewEventAction: 'View qa facilities suggestions page',
      },
      {
        hideInDrawer: true,
        key: 'facilitiesSuggestionsForm',
        path: 'facilities-suggestions-form',
        TabElement: FacilitiesSuggestionsFormTab,
        titleKey: 'cycling-insights.qa.navigation.facilities_suggestions',
        enableMap: true,
        enableFacilities: true,
        pageViewEventAction: 'View qa facilities suggestions form page',
      },
    ],
    titleKey: 'commons.navigation.cartographic_data',
    descriptionKey: 'commons.navigation.cartographic_data_description',
    icon: <MapOutlined />,
  },
  {
    pageKey: DashboardPages.Parkings,
    path: 'parkings',
    PageElement: ParkingsPage,
    titleKey: 'commons.navigation.parkings',
    icon: <ParkingsIcon />,
    tabs: [
      {
        key: 'parkings',
        path: 'cycle-parkings',
        TabElement: ParkingsTab,
        titleKey: 'commons.navigation.parkings',
        hasParkingRequestsToggle: true,
        hasParkingsToggles: true,
        enableMap: true,
        enableRightPanel: true,
        rightPanelProps: { width: 350 },
        pageViewEventAction: 'View cartographic data parkings page',
      },
      {
        hideInDrawer: true,
        key: 'parkingsStats',
        path: 'cycle-parkings-stats',
        TabElement: ParkingsStatsTab,
        titleKey: 'cycling-insights.cartographic_data.parkings.navigation.stats',
        disableLeftPanel: true,
        pageViewEventAction: 'View parkings stats page',
        whiteBackground: true,
      },
      {
        hideInDrawer: true,
        key: 'parkingsReports',
        path: 'cycle-parkings-reports',
        TabElement: ParkingsReportsTab,
        titleKey: 'cycling-insights.reports.navigation.osm_cartographic_reports',
        enableMap: true,
        enableFacilities: true,
        hasFacilitiesLegend: true,
        enableRightPanel: true,
        rightPanelProps: { width: 350 },
        pageViewEventAction: 'View parkings reports page',
        whiteBackground: true,
      },
    ],
  },
  {
    pageKey: DashboardPages.Promotion,
    path: 'promotion',
    PageElement: PromotionPage,
    tabs: [
      {
        key: 'animation',
        path: 'animation',
        TabElement: AnimationTab,
        titleKey: 'commons.navigation.animation',
        disableLeftPanel: true,
        pageViewEventAction: 'View animation hub page',
      },
      {
        hideInDrawer: true,
        key: 'communityMembers',
        path: 'members',
        TabElement: MembersTab,
        titleKey: 'cycling-insights.community.navigation.members',
        disableLeftPanel: true,
        pageViewEventAction: 'View animation communication members page',
      },
      {
        hideInDrawer: true,
        key: 'communityChallenges',
        path: 'challenges',
        TabElement: ChallengesTab,
        disableLeftPanel: true,
        titleKey: 'cycling-insights.community.navigation.challenges',
        pageViewEventAction: 'View animation communication challenges page',
      },
      {
        hideInDrawer: true,
        key: 'communityChallengesForm',
        path: 'new-challenge',
        TabElement: ChallengesFormTab,
        disableLeftPanel: true,
        titleKey: 'cycling-insights.community.navigation.challenges',
        pageViewEventAction: 'View animation communication challenges form page',
      },
      {
        hideInDrawer: true,
        key: 'communityNews',
        path: 'news-feed',
        TabElement: NewsFeedTab,
        titleKey: 'cycling-insights.community.navigation.news',
        disableLeftPanel: true,
        pageViewEventAction: 'View animation communication news feed page',
      },
      {
        hideInDrawer: true,
        key: 'communityNewsForm',
        path: 'new-post',
        TabElement: NewsFormTab,
        disableLeftPanel: true,
        titleKey: 'cycling-insights.community.navigation.news',
        pageViewEventAction: 'View animation communication news form page',
      },
      {
        key: 'widgets',
        path: 'widgets',
        TabElement: WidgetsTab,
        titleKey: 'cycling-insights.community.navigation.widgets',
        pageViewEventAction: 'View animation communication widgets page',
      },
      ridesTab,
    ],
    titleKey: 'commons.navigation.community',
    descriptionKey: 'commons.navigation.community_description',
    icon: <CommunityIcon />,
  },
  {
    pageKey: DashboardPages.Api,
    path: 'api',
    PageElement: ApiPage,
    tabs: [
      {
        key: 'apiKeys',
        path: 'keys',
        TabElement: ApiKeysTab,
        titleKey: 'cycling-insights.api.navigation.keys',
        pageViewEventAction: 'View api keys page',
      },
      {
        key: 'apiDoc',
        path: 'documentation',
        TabElement: DocumentationTab,
        titleKey: 'cycling-insights.api.navigation.doc',
        disableLeftPanel: true,
        pageViewEventAction: 'View api documentation page',
      },
      {
        key: 'apiAvailability',
        path: 'availability',
        TabElement: AvailabilityTab,
        titleKey: 'cycling-insights.api.navigation.availability',
        disableLeftPanel: true,
        pageViewEventAction: 'View api availability page',
      },
    ],
    titleKey: 'commons.navigation.api',
    descriptionKey: 'commons.navigation.api_description',
    icon: <HubOutlined />,
  },
  {
    pageKey: DashboardPages.QA,
    path: 'qa',
    PageElement: QAPage,
    tabs: [
      bikeRoutesTab,
      {
        key: 'qaRefRoutes',
        path: 'ref-routes',
        TabElement: RefRoutesTab,
        titleKey: 'cycling-insights.qa.navigation.ref_routes',
        pageViewEventAction: 'View qa ref routes page',
      },
      {
        key: 'itineraryReports',
        path: 'itinerary-reports',
        TabElement: ItineraryReportsTab,
        titleKey: 'cycling-insights.reports.navigation.itinerary_reports',
        enableMap: true,
        enableRightPanel: true,
        hasFacilitiesToggle: true,
        pageViewEventAction: 'View qa itinerary reports page',
      },
      {
        key: 'qaUsersLogs',
        path: 'users-logs',
        TabElement: UsersLogsTab,
        titleKey: 'cycling-insights.qa.navigation.users_logs',
        pageViewEventAction: 'View users logs page',
      },
      {
        key: 'osmReports',
        path: 'osm-reports',
        TabElement: OSMReportsTab,
        titleKey: 'cycling-insights.reports.navigation.osm_cartographic_reports',
        enableMap: true,
        enableRightPanel: true,
        hasFacilitiesToggle: true,
        hasParkingsToggles: true,
        pageViewEventAction: 'View cartographic data OSM reports page',
        whiteBackground: true,
      },
      {
        hideInDrawer: true,
        key: 'newReport',
        path: 'new-report',
        TabElement: NewReportTab,
        titleKey: 'cycling-insights.reports.navigation.new_report',
        enableMap: true,
        pageViewEventAction: 'View new contribution page',
        whiteBackground: true,
      },
      {
        key: 'exclusionZones',
        path: 'exclusion-zones',
        TabElement: ExclusionZonesTab,
        titleKey: 'cycling-insights.qa.navigation.exclusion_zones',
        enableMap: true,
        pageViewEventAction: 'View qa exclusion zones page',
      },
      {
        hideInDrawer: true,
        key: 'exclusionZonesForm',
        path: 'exclusion-zones-form',
        TabElement: ExclusionZonesFormTab,
        titleKey: 'cycling-insights.qa.navigation.exclusion_zones',
        enableMap: true,
        pageViewEventAction: 'View qa exclusion zones form page',
      },
    ],
    titleKey: 'commons.navigation.qa',
    descriptionKey: 'commons.navigation.qa_description',
    icon: <CheckBoxOutlined />,
  },
  {
    pageKey: DashboardPages.GIS,
    path: 'gis',
    PageElement: GISPage,
    titleKey: 'cycling-insights.facilities.navigation.gis_files',
    icon: <UploadFileOutlined />,
    tabs: [
      {
        key: 'facilitiesGISFiles',
        path: 'send-data',
        TabElement: SendDataTab,
        titleKey: 'cycling-insights.facilities.navigation.gis_files',
        disableLeftPanel: true,
        pageViewEventAction: 'View cartographic data send data page',
      },
    ],
  },
  {
    pageKey: DashboardPages.Downloads,
    path: 'downloads',
    PageElement: DownloadsPage,
    titleKey: 'commons.navigation.downloads',
    descriptionKey: 'commons.navigation.downloads_description',
    icon: <DownloadIcon />,
  },
];

export default pages;
