import { Flow } from '@geovelo-frontends/commons';
import { Trans } from 'react-i18next';

import Table from '../../../../components/table';
import useOriginDestinationTable from '../../../../hooks/origin-destination-table';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';

type TKey = 'origin' | 'destination' | 'percentage' | 'count';

const keys: TKey[] = ['origin', 'destination', 'percentage', 'count'];

function OriginDestinationTable({
  data,
  oldOriginDestination: { zones, externalZones },
}: IBicycleObservatoryPageContext & TOutletContext & { data?: Flow[] }): JSX.Element {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    rows,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
  } = useOriginDestinationTable<TKey>({
    defaultOrderBy: 'count',
    flows: data,
    zones,
    externalZones,
  });

  return (
    <Table
      count={data?.length}
      headers={{
        origin: {
          label: <Trans i18nKey="cycling-insights.usage.origin_destination.origin" />,
          sortable: true,
          width: 130,
        },
        destination: {
          label: <Trans i18nKey="cycling-insights.usage.origin_destination.destination" />,
          sortable: true,
          width: 130,
        },
        percentage: {
          width: 60,
        },
        count: {
          label: <Trans i18nKey="commons.stats_types.journeys" />,
          sortable: true,
          width: 50,
        },
      }}
      keys={keys}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onSortRequest={onSortRequest}
      order={order}
      orderBy={orderBy}
      page={page}
      rows={rows}
      rowsPerPage={rowsPerPage}
      tableProps={{ sx: { tableLayout: 'fixed' } }}
      title="Cartographic contributions table"
    />
  );
}

export default OriginDestinationTable;
