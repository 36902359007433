import { ParkingTypes, Period, prevMonth } from '@geovelo-frontends/commons';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PeriodForm from '../../../../components/form/period';
import Progression from '../../../../components/progression';
import TabIntroduction from '../../../../components/tab-introduction';
import Table, { TCells, THeader, TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSortableTable from '../../../../hooks/table/sortable';
import { parkingsTypes } from '../../../../models/parkings-types';
import { TParkingsPageContext } from '../../context';
import useParkings from '../../hooks/parkings';

type TKey = 'name' | 'total' | 'progression' | ParkingTypes;

const keys: TKey[] = [
  'name',
  'total',
  'progression',
  ParkingTypes.Arch,
  ParkingTypes.Rack,
  ParkingTypes.Sheltered,
  ParkingTypes.Secure,
  ParkingTypes.Free,
  ParkingTypes.Private,
];

function ParkingsStatsTable(context: TParkingsPageContext): JSX.Element {
  const {
    defaultPeriods,
    period,
    parkings: { cyclabilityZones, stats, prevStats },
  } = context;
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  useParkings({ context });
  const { page, rowsPerPage, setPage, paginate, onPageChange, onRowsPerPageChange } =
    usePaginatedTable<number, TKey>(10);
  const { orderBy, order, sort, onSortRequest } = useSortableTable<number, TKey>(
    'progression',
    'desc',
    {
      setPage,
    },
  );

  useEffect(() => {
    if (cyclabilityZones) {
      const _rows = [...cyclabilityZones].reduce<TRow<number, TKey>[]>(
        (res, { id: key, name, statsBNSC }) => {
          const count = statsBNSC[0].slots.all;
          const prevCount = prevStats ? prevStats[key].slots.all : undefined;

          res.push({
            key,
            cells: {
              name: { value: name },
              total: { value: count },
              progression: {
                value: prevCount !== undefined ? count - prevCount : null,
                format: () => <Progression prevStat={prevCount} stat={count} />,
              },
              ...parkingsTypes.reduce<TCells<TKey>>((res, { key }) => {
                res[key] = {
                  value: statsBNSC[0].slots[key],
                };

                return res;
              }, {}),
            },
          });

          return res;
        },
        [],
      );

      paginate(sort(_rows, 'name'));

      setRows(paginate(sort(_rows, 'name')));
    } else {
      setRows(undefined);
    }
  }, [cyclabilityZones, stats, prevStats, orderBy, order, page, rowsPerPage]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      padding={3}
      sx={{ overflowY: 'auto' }}
    >
      <Typography
        color="primary"
        component="h2"
        flexDirection="column"
        fontWeight="700"
        lineHeight="2rem"
        variant="h5"
      >
        <IconButton component={Link} sx={{ marginRight: '8px' }} to="../cycle-parkings">
          <ArrowBackIosNew color="primary" />
        </IconButton>
        <Trans i18nKey="cycling-insights.cartographic_data.parkings.navigation.stats" />
      </Typography>
      <TabIntroduction title="cycling-insights.cartographic_data.introduction.parkings" />
      <Box marginTop={5}>
        <PeriodForm
          disablePadding
          customPeriodTypes={{ defaultPeriods, enabledTypes: ['month'] }}
          maxPeriod={moment().get('date') <= 7 ? prevMonth : undefined}
          minPeriod={
            new Period(
              'month',
              moment('2021-10').startOf('month'),
              moment('2021-10').endOf('month'),
            )
          }
          {...period}
        />
      </Box>
      <TableWrapper>
        <Table
          hasBorder
          count={cyclabilityZones?.length}
          headers={{
            name: {
              label: (
                <Typography fontWeight={600} variant="caption">
                  <Trans i18nKey="cycling-insights.cartographic_data.parkings.table.zone" />
                </Typography>
              ),
              sortable: true,
              width: 150,
            },
            total: {
              label: (
                <Typography fontWeight={600} variant="caption">
                  <Trans i18nKey="cycling-insights.cartographic_data.parkings.table.total" />
                </Typography>
              ),
              sortable: true,
            },
            progression: {
              label: (
                <Typography fontWeight={600} variant="caption">
                  <Trans
                    i18nKey={
                      period.comparisonEnabled
                        ? 'commons.stats.progression_label_short'
                        : 'cycling-insights.cartographic_data.parkings.table.progression'
                    }
                  />
                </Typography>
              ),
              sortable: true,
            },
            ...parkingsTypes.reduce<{ [key in ParkingTypes]?: THeader }>(
              (res, { key, titleKey }) => {
                res[key] = {
                  label: (
                    <Typography fontWeight={600} variant="caption">
                      <Trans i18nKey={titleKey} />
                    </Typography>
                  ),
                  sortable: true,
                };

                return res;
              },
              {},
            ),
          }}
          keys={keys}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onSortRequest={onSortRequest}
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          tableProps={{ sx: { tableLayout: 'fixed' } }}
          title="Cyclability zones table"
        />
      </TableWrapper>
    </Box>
  );
}

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: hidden;
`;

export default ParkingsStatsTable;
